import { Divider, Modal } from "antd";
import React from "react";

const CustomModal = ({ children, modal, setModal, title, onCancel }) => {
  return (
    <Modal
      width={"50%"}
      title={title}
      maskClosable={false}
      open={modal}
      onCancel={() => {
        setModal(false);
      }}
      onClose={() => {
        setModal(false);
      }}
      footer={null}
    >
      <Divider className=" my-3 w-full" />
      {children}
    </Modal>
  );
};

export default CustomModal;
