import { combineReducers } from "@reduxjs/toolkit";
import crudReducer from "./crudSlice";
import authReducer from "./authSlice";

const rootReducer = combineReducers({
    crud: crudReducer,
    auth: authReducer,
});

export default rootReducer;
