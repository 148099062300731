import React from 'react'

const Dashboard = () => {
    return (
        <svg className=' mr-3' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.11111 11.1111H7.77778C8.07246 11.1111 8.35508 10.994 8.56345 10.7857C8.77183 10.5773 8.88889 10.2947 8.88889 10V1.11111C8.88889 0.816426 8.77183 0.533811 8.56345 0.325437C8.35508 0.117063 8.07246 0 7.77778 0H1.11111C0.816426 0 0.533811 0.117063 0.325437 0.325437C0.117063 0.533811 0 0.816426 0 1.11111V10C0 10.2947 0.117063 10.5773 0.325437 10.7857C0.533811 10.994 0.816426 11.1111 1.11111 11.1111ZM0 18.8889C0 19.1836 0.117063 19.4662 0.325437 19.6746C0.533811 19.8829 0.816426 20 1.11111 20H7.77778C8.07246 20 8.35508 19.8829 8.56345 19.6746C8.77183 19.4662 8.88889 19.1836 8.88889 18.8889V14.4444C8.88889 14.1498 8.77183 13.8671 8.56345 13.6588C8.35508 13.4504 8.07246 13.3333 7.77778 13.3333H1.11111C0.816426 13.3333 0.533811 13.4504 0.325437 13.6588C0.117063 13.8671 0 14.1498 0 14.4444V18.8889ZM11.1111 18.8889C11.1111 19.1836 11.2282 19.4662 11.4365 19.6746C11.6449 19.8829 11.9275 20 12.2222 20H18.8889C19.1836 20 19.4662 19.8829 19.6746 19.6746C19.8829 19.4662 20 19.1836 20 18.8889V11.1111C20 10.8164 19.8829 10.5338 19.6746 10.3254C19.4662 10.1171 19.1836 10 18.8889 10H12.2222C11.9275 10 11.6449 10.1171 11.4365 10.3254C11.2282 10.5338 11.1111 10.8164 11.1111 11.1111V18.8889ZM12.2222 7.77778H18.8889C19.1836 7.77778 19.4662 7.66071 19.6746 7.45234C19.8829 7.24397 20 6.96135 20 6.66667V1.11111C20 0.816426 19.8829 0.533811 19.6746 0.325437C19.4662 0.117063 19.1836 0 18.8889 0H12.2222C11.9275 0 11.6449 0.117063 11.4365 0.325437C11.2282 0.533811 11.1111 0.816426 11.1111 1.11111V6.66667C11.1111 6.96135 11.2282 7.24397 11.4365 7.45234C11.6449 7.66071 11.9275 7.77778 12.2222 7.77778Z" fill="currentColor" />
        </svg>

    )
}

export default Dashboard