import React, { useEffect, useState } from "react";
import { Form, Input, Upload, Button, Avatar, Divider, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../../redux/reducers/authSlice";
import axios from "axios";

const EditProfileForm = ({ setModal, loading }) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.authUser);
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (authUser) {
      form.setFieldsValue({
        fullName: authUser.fullName,
        phoneNumber: authUser.phoneNumber,
      });
      setPhone(authUser.phoneNumber);
      setImageUrl(
        authUser.profileImage || "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png"
      );
    }
  }, [authUser, form]);

  const handleSave = () => {
    const values = form.getFieldsValue();
    let obj = {
      fullName: values.fullName,
      phoneNumber: phone,
      profileImage: imageUrl,
    };
    console.log("obj===", obj);
    dispatch(updateUserProfile({ apiName: "updateProfile", data: obj }));

    setImageUrl("");
    setModal(false); // Close the modal after saving
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setUploading(true);
      const response = await axios.post(`${process.env.REACT_APP_ROOT_URL}/uploadImages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setImageUrl(response.data.url);
      message.success("Image uploaded successfully!");
    } catch (error) {
      console.error("Upload failed:", error);
      message.error("Image upload failed.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Spin spinning={uploading}>
      <Form form={form} layout="vertical" initialValues={authUser} onFinish={handleSave}>
        <Divider className="mt-1 mb-2" />
        <div style={{ display: "flex", justifyContent: "space-between", gap: "40px" }}>
          <div style={{ flexBasis: "60%", marginTop: "20px" }}>
            <Form.Item label="Full Name" name="fullName" rules={[{ required: true, message: "Please input your full name!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label={<span className="font-roboto-medium text-[17px]">Phone Number</span>} name="phoneNumber">
              <PhoneInput
                country={"gb"}
                value={phone}
                countryCodeEditable={false} // Make country code uneditable
                onChange={(value, country) => {
                  setPhone(value);
                }}
                inputStyle={{ background: "#0101010D", width: "100%" }}
              />
            </Form.Item>
          </div>
          <div style={{ flexBasis: "35%", textAlign: "center" }}>
            <Form.Item className="mt-5">
              {imageUrl ? (
                <Avatar className="bg-gray-50 border" shape="square" src={imageUrl} size={100} />
              ) : (
                <Avatar size={120} icon={<UploadOutlined />} />
              )}
            </Form.Item>
            <Form.Item>
              <Upload
                maxCount={1}
                name="avatar"
                listType="picture"
                showUploadList={false}
                beforeUpload={(file) => {
                  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
                  if (!isJpgOrPng) {
                    message.error("You can only upload JPG/PNG file!");
                    return Upload.LIST_IGNORE;
                  }
                  const isSizeValid = file.size / 1024 / 1024 < 2; // 2MB
                  if (!isSizeValid) {
                    message.error("Image must be smaller than 2MB!");
                    return Upload.LIST_IGNORE;
                  }
                  handleUpload(file);
                  return false; // Prevent automatic upload
                }}
              >
                <Button loading={uploading} icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </div>
        </div>
        <Divider className="mb-1 mt-0" />
        <div className="flex justify-end">
          <Button type="primary" className="cancel-button font-roboto-bold text-lg p-5" onClick={() => setModal(false)}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="save-button font-roboto-bold text-lg p-5"
            htmlType="submit"
            style={{ backgroundColor: "yellow", color: "black" }}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default EditProfileForm;
