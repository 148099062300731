import React, { useEffect, useState } from "react";
import { Button, Divider, Table, Modal, Spin, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BiPlus } from "react-icons/bi";
import { DataGetAction, DataRequestAction } from "../../redux/actions/CommonHttp";
import CustomModal from "../../components/Modal/CustomModal";
import ActionMenu from "../../components/ActionMenu";
import AddBroads from "./components/AddBroads";
import EditBroads from "./components/EditBroads";
import TextArea from "antd/es/input/TextArea";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { resetCrudState } from "../../redux/reducers/crudSlice";

const BroadcastTable = () => {
  const dispatch = useDispatch();
  const broadcastsData = useSelector((state) => state.crud.broadcasts.data);
  const broadcastsPagination = useSelector((state) => state.crud.broadcasts.pagination || { current: 1, pageSize: 10, total: 0 });
  const addRecordSuccess = useSelector((state) => state.crud.addRecordSuccess);
  const editRecordSuccess = useSelector((state) => state.crud.editRecordSuccess);
  const deleteRecordSuccess = useSelector((state) => state.crud.deleteRecordSuccess);
  const deleteRecordSpin = useSelector((state) => state.crud.deleteRecordSpin);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");

  useEffect(() => {
    if (addRecordSuccess) {
      setAddModal(false);
      dispatch(resetCrudState()); // Reset state after successful addition
      fetchData(1); // Re-fetch data after addition
    }
  }, [addRecordSuccess, dispatch]);

  useEffect(() => {
    if (editRecordSuccess) {
      setEditModal(false);
      dispatch(resetCrudState());
      fetchData(broadcastsPagination.current); // Re-fetch data after update
    }
  }, [editRecordSuccess, dispatch]);

  useEffect(() => {
    if (deleteRecordSuccess) {
      setDeleteModal(false);
      dispatch(resetCrudState());
      fetchData(1); // Re-fetch data after deletion
    }
  }, [deleteRecordSuccess, dispatch]);

  useEffect(() => {
    fetchData(broadcastsPagination.current); // Fetch initial data for the first page
  }, [dispatch, broadcastsPagination.current]);

  const fetchData = (page) => {
    const params = {
      page: page,
      pageSize: broadcastsPagination.pageSize,
    };

    dispatch(
      DataGetAction({
        apiName: "getAllBroadcast",
        data: params,
        requestType: "fetch",
        recordName: "broadcasts",
      })
    );
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handleAddData = (formData) => {
    dispatch(
      DataRequestAction({
        method: "POST",
        apiName: "addBroadcast",
        data: formData,
        requestType: "addRecord",
        recordName: "broadcasts",
      })
    );
  };

  const handleEditData = (formData) => {
    dispatch(
      DataRequestAction({
        method: "PUT",
        apiName: "updateBroadcast",
        data: formData,
        requestType: "updateRecord",
        recordName: "broadcasts",
      })
    ).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        fetchData(broadcastsPagination.current); // Re-fetch data after update
      }
    });
    setSelectedRow(null);
  };

  const handleDelete = () => {
    if (selectedRow) {
      dispatch(
        DataRequestAction({
          method: "DELETE",
          apiName: `deleteBroadcast`,
          data: { id: selectedRow?._id },
          requestType: "deleteRecord",
          recordName: "broadcasts",
        })
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          fetchData(1); // Re-fetch data after deletion
        }
      });
    }
  };

  const confirmDelete = (record) => {
    setSelectedRow(record);
    setDeleteModal(true);
  };

  const handleDescriptionClick = (description) => {
    setSelectedDescription(description);
    setDescriptionModal(true);
  };

  const openEditModal = (record) => {
    setSelectedRow(record);
    setEditModal(true);
  };

  const columns2 = [
    {
      title: "ID",
      dataIndex: "broadcastId",
      key: "broadcastId",
      className: "table-cell font-semibold",
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      className: "table-cell font-semibold",
      render: (text, record) => (
        <div className="flex flex-row items-center gap-2">
          <p className="capitalize">{record?.title}</p>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      className: "table-cell",
      render: (text) => <a onClick={() => handleDescriptionClick(text)}>{text?.slice(0, 40) + "..."}</a>,
    },
    {
      title: "",
      key: "action",
      className: "table-cell",
      render: (text, record) => (
        <ActionMenu
          menuItems={[
          //  { label: "Edit", onClick: () => openEditModal(record) },
            { label: "Delete", onClick: () => confirmDelete(record) },
          ]}
        />
      ),
    },
  ];

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <h2 className="font-roboto-bold text-20 text-primary-text">Broadcast Messages</h2>
        <Button size="large" onClick={() => setAddModal(true)} className="font-roboto-medium text-xl home-add-button ">
          <BiPlus size={20} />
          Add Broadcast
        </Button>
      </div>
      <Divider className="my-5" />
      <Spin spinning={deleteRecordSpin}>
        <Table
          dataSource={broadcastsData}
          columns={columns2}
          pagination={false} // Disable internal pagination
        />
      </Spin>
      <Pagination
        className="pt-4 flex justify-end"
        current={broadcastsPagination.current}
        pageSize={broadcastsPagination.pageSize}
        total={broadcastsPagination.total}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
      <CustomModal title="Add Broadcast" modal={addModal} setModal={setAddModal}>
        <AddBroads handleSubmit={handleAddData} setModal={setAddModal} />
      </CustomModal>
      <CustomModal title="Edit Broadcast" modal={editModal} setModal={setEditModal}>
        <EditBroads record={selectedRow} handleSubmit={handleEditData} setModal={setEditModal} />
      </CustomModal>
      <Modal
        title="Description"
        className="mt-40"
        open={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
        footer={null}
      >
        <Divider className="mt-0 mb-3" />
        <TextArea rows={6} value={selectedDescription} readOnly style={{ width: "100%" }} />
      </Modal>
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaRegCircleQuestion size={28} style={{ color: "#ff4d4f", marginRight: 10, marginTop: "10px" }} />
            <span className="text-lg font-roboto-bold">Are you sure you want to delete this information?</span>
          </div>
        }
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setDeleteModal(false)}
            className="cancel-button text-lg p-5"
            style={{ marginRight: "0px", fontFamily: "Roboto-Bold" }}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={handleDelete}
            className="save-button text-lg p-5"
            style={{ fontFamily: "Roboto-Bold" }}
            loading={deleteRecordSpin}
          >
            OK
          </Button>,
        ]}
      >
        <p className="ml-10 mb-5">This information will be deleted permanently.</p>
      </Modal>
    </div>
  );
};

export default BroadcastTable;
