import * as React from "react";
const Traffic = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    className=" mr-3"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path
        d="M272 450 c-13 -6 -29 -24 -36 -42 -13 -31 -15 -31 -77 -30 -36 2 -75
   -3 -88 -10 -37 -19 -73 -116 -69 -187 3 -55 3 -56 36 -59 22 -2 34 2 38 12 4
   11 19 16 45 16 l38 0 3 -52 c3 -52 4 -53 36 -56 22 -2 34 2 38 12 9 23 139 23
   148 0 4 -10 16 -14 38 -12 l33 3 0 77 c-1 70 1 77 20 80 52 8 14 215 -46 245
   -28 15 -123 16 -157 3z m143 -16 c16 -6 45 -54 45 -74 0 -13 -206 -14 -215 0
   -7 12 20 66 37 73 19 8 114 8 133 1z m-185 -94 c27 -27 25 -43 -7 -57 -33 -16
   -183 -17 -183 -3 0 18 29 68 42 73 7 3 39 6 71 6 44 1 61 -3 77 -19z m250 -51
   c0 -37 -2 -39 -30 -39 -36 0 -39 11 -7 25 12 6 22 17 22 25 0 20 -46 26 -59 8
   -15 -20 -136 -12 -136 8 0 10 24 14 98 16 53 2 100 2 105 0 4 -2 7 -21 7 -43z
   m-105 -15 c16 -6 45 -54 45 -74 0 -6 -40 -10 -110 -10 -70 0 -110 4 -110 10 0
   18 29 68 42 73 19 8 114 8 133 1z m-185 -32 c0 -3 -5 -21 -12 -39 l-12 -33
   -73 0 -73 0 0 38 c0 21 2 41 4 43 7 6 166 -2 166 -9z m283 -19 c-7 -2 -19 -2
   -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-35 -92 l3 -41 -130 0 -131 0 0 38
   c0 21 2 41 4 43 2 2 60 3 128 2 l123 -2 3 -40z m-385 12 c-7 -2 -19 -2 -25 0
   -7 3 -2 5 12 5 14 0 19 -2 13 -5z m160 -80 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12
   5 14 0 19 -2 13 -5z m220 0 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13
   -5z"
      />
      <path
        d="M35 230 c-10 -16 15 -41 39 -38 32 5 28 42 -6 46 -14 2 -29 -2 -33
   -8z"
      />
      <path
        d="M195 150 c-10 -16 15 -41 39 -38 32 5 28 42 -6 46 -14 2 -29 -2 -33
   -8z"
      />
      <path d="M364 145 c-8 -19 3 -35 25 -35 22 0 45 25 36 40 -9 15 -55 12 -61 -5z" />
    </g>
  </svg>
);
export default Traffic;
