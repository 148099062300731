import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { sessionExpired } from "../reducers/authSlice"; // Adjust the import path based on your project structure

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const DataRequestAction = createAsyncThunk(
  "data/request",
  async (
    {
      method,
      apiName,
      data,
      requestType,
      recordName,
      loaderCase,
      modalName,
      showNotification = true,
    },
    { dispatch, rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${ROOT_URL}/${apiName}`, {
        method: method,
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: token,
        }),
        body: JSON.stringify(data),
      });

      if (response.status === 401) {
        dispatch(sessionExpired()); // Trigger session expired action
        const errorMessage = await response.json();
        message.error(
          errorMessage.message || "Session expired. Please log in again."
        );
        return rejectWithValue({
          error: "Unauthorized",
          recordName,
          requestType,
        });
      }

      const responseData = await response.json();
      console.log("===responseData==", responseData);
      if (responseData.status === "SUCCESS") {
        if (showNotification) {
          message.success(responseData.message);
        }
        return { responseData, recordName, requestType };
      }
      if (responseData.status === "ERROR" || response.status === 404) {
        const errorMessage = responseData.message;
        message.error(errorMessage);
        return rejectWithValue({ errorMessage, recordName, requestType });
      }
    } catch (error) {
      console.error("====response error====", error);
      message.error(error.message);
      return rejectWithValue({ error: error.message, recordName, requestType });
    }
  }
);

export const DataGetAction = createAsyncThunk(
  "data/get",
  async (
    { apiName, data, requestType, recordName, loaderCase, modalName = "" },
    { dispatch, rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    let param = "";
    if (data) {
      param = `/${JSON.stringify(data)}`;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
      APIType: "web",
    };

    try {
      const response = await fetch(`${ROOT_URL}/${apiName}${param}`, {
        headers,
      });

      if (response.status === 401) {
        dispatch(sessionExpired());
        const errorMessage = await response.json();
        message.error(
          errorMessage.message || "Session expired. Please log in again."
        );
        return rejectWithValue({
          error: "Unauthorized",
          recordName,
          requestType,
        });
      }

      if (response.status === 404) {
        const errorMessage = await response.json();
        message.error(errorMessage.message);
        return rejectWithValue({
          error: errorMessage.message,
          recordName,
          requestType,
        });
      }

      const responseData = await response.json();
      console.log("responseData", responseData); // This should include both the Record and pagination
      return { responseData, recordName, requestType }; // Ensure it returns in this format
    } catch (error) {
      console.error("====catch error====", error);
      return rejectWithValue({ error, recordName, requestType });
    }
  }
);
