// import {
//   Button,
//   Divider,
//   Table,
//   Modal,
//   Carousel,
//   Spin,
//   Tooltip,
//   Pagination,
// } from "antd";
// import React, { useEffect, useState, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   DataGetAction,
//   DataRequestAction,
// } from "../../redux/actions/CommonHttp";
// import moment from "moment";
// import { BiPlus } from "react-icons/bi";
// import AddEvents from "./components/AddEvents";
// import CustomModal from "../../components/Modal/CustomModal";
// import EditEvents from "./components/EditEvent";
// import ActionMenu from "../../components/ActionMenu";
// import { resetCrudState } from "../../redux/reducers/crudSlice"; // Assuming you have a reset action
// import { FaRegCircleQuestion } from "react-icons/fa6";

// const EventsTable = () => {
//   const dispatch = useDispatch();
//   const event = useSelector((state) => state.crud.events);
//   const addRecordSpin = useSelector((state) => state.crud.addRecordSpin);
//   const editRecordSpin = useSelector((state) => state.crud.editRecordSpin);
//   const deleteRecordSpin = useSelector((state) => state.crud.deleteRecordSpin);
//   const addRecordSuccess = useSelector((state) => state.crud.addRecordSuccess);
//   const [pagination, setPagination] = useState({
//     current: 1,
//     pageSize: 10,
//     total: 0,
//   });

//   const editRecordSuccess = useSelector(
//     (state) => state.crud.editRecordSuccess
//   );
//   const deleteRecordSuccess = useSelector(
//     (state) => state.crud.deleteRecordSuccess
//   );

//   const [addModal, setAddModal] = useState(false);
//   const [editModal, setEditModal] = useState(false);
//   const [imageModal, setImageModal] = useState(false);
//   const [deleteModal, setDeleteModal] = useState(false);
//   const [selectedEvent, setSelectedEvent] = useState(null);
//   const [selectedImages, setSelectedImages] = useState([]);
//   const [activeImage, setActiveImage] = useState(0);
//   const [imageLoading, setImageLoading] = useState(false);
//   const carouselRef = useRef(null);

//   useEffect(() => {
//     if (addRecordSuccess) {
//       setAddModal(false);
//       dispatch(resetCrudState());
//     }
//   }, [addRecordSuccess, dispatch]);

//   useEffect(() => {
//     if (editRecordSuccess) {
//       setEditModal(false);
//       dispatch(resetCrudState());
//     }
//   }, [editRecordSuccess, dispatch]);

//   useEffect(() => {
//     if (deleteRecordSuccess) {
//       setDeleteModal(false);
//       dispatch(resetCrudState());
//     }
//   }, [deleteRecordSuccess, dispatch]);

//   useEffect(() => {
//     dispatch(
//       DataGetAction({
//         apiName: "getEvents",
//         data: "",
//         requestType: "fetch",
//         recordName: "events",
//       })
//     );
//   }, [dispatch]);

//   const handleAddData = (formData) => {
//     dispatch(
//       DataRequestAction({
//         method: "POST",
//         apiName: "addEvent",
//         data: formData,
//         requestType: "addRecord",
//         recordName: "events",
//       })
//     );
//   };

//   const handleEdit = (record) => {
//     setSelectedEvent(record); // Set the selected event data
//     setEditModal(true); // Open the edit modal
//   };

//   const handleEditData = (formData) => {
//     dispatch(
//       DataRequestAction({
//         method: "PUT",
//         apiName: "updateEvent",
//         data: formData,
//         requestType: "updateRecord",
//         recordName: "events",
//       })
//     );
//   };

//   const handleDelete = () => {
//     if (selectedEvent) {
//       dispatch(
//         DataRequestAction({
//           method: "DELETE",
//           apiName: `deleteEvents`,
//           data: { id: selectedEvent?._id },
//           requestType: "deleteRecord",
//           recordName: "events",
//         })
//       );
//     }
//   };

//   const handleConfirmDelete = (record) => {
//     setSelectedEvent(record);
//     setDeleteModal(true);
//   };

//   const handleViewImages = (images) => {
//     setSelectedImages(images);
//     setActiveImage(0);
//     setImageLoading(true); // Start spinner
//     setImageModal(true);

//     // Simulate image loading delay
//     setTimeout(() => {
//       setImageLoading(false); // Stop spinner
//     }, 1000); // Adjust this time as needed
//   };
//   const handleThumbnailClick = (index) => {
//     setActiveImage(index);
//     carouselRef.current.goTo(index, true);
//   };

//   const sortedEvents = event
//     ? [...event].sort((a, b) => (b._id > a._id ? 1 : -1))
//     : [];

//   const columns2 = [
//     {
//       title: "ID",
//       dataIndex: "eventId",
//       key: "eventId",
//       className: "table-cell font-semibold",
//     },
//     {
//       title: "Name",
//       dataIndex: "name",
//       key: "name",
//       className: "table-cell font-semibold",
//     },
//     {
//       title: "Date & Time",
//       dataIndex: "startTime",
//       className: "table-cell",
//       key: "startTime",
//       render: (_, record) => {
//         const { date, startTime, endTime } = record;
//         const formattedDate = moment(date).format("DD-MM-YYYY");
//         const formattedStartTime = moment(startTime, "HH:mm:ss").format(
//           "hh:mm A"
//         );
//         const formattedEndTime = endTime
//           ? moment(endTime, "HH:mm:ss").format("hh:mm A")
//           : "NA";
//         return (
//           <div>
//             <span>{formattedDate}</span>
//             <br />
//             <span>
//               {formattedStartTime} - {formattedEndTime}
//             </span>
//           </div>
//         );
//       },
//     },
//     {
//       title: "Venue",
//       dataIndex: "venue",
//       key: "venue",
//       className: "table-cell",
//       render: (text) =>
//         text && text.length > 50 ? (
//           <Tooltip
//             color="#E8BC0E"
//             className="cursor-pointer"
//             title={
//               <div
//                 className="text-black font-roboto-regular text-[16px] cursor-pointer"
//                 style={{ maxHeight: "150px", overflowY: "auto" }}
//               >
//                 {text}
//               </div>
//             }
//           >
//             {text.slice(0, 50) + "..."}
//           </Tooltip>
//         ) : (
//           <span>{text || "---"}</span>
//         ),
//     },
//     {
//       title: "Address",
//       dataIndex: "address",
//       key: "address",
//       className: "table-cell",
//       render: (text) =>
//         text && text.length > 50 ? (
//           <Tooltip
//             color="#E8BC0E"
//             className="cursor-pointer"
//             title={
//               <div
//                 className="text-black font-roboto-regular text-[16px] cursor-pointer"
//                 style={{ maxHeight: "150px", overflowY: "auto" }}
//               >
//                 {text}
//               </div>
//             }
//           >
//             {text.slice(0, 50) + "..."}
//           </Tooltip>
//         ) : (
//           <span>{text || "---"}</span>
//         ),
//     },

//     {
//       title: "Images",
//       key: "images",
//       className: "table-cell",
//       render: (dat) => (
//         <div
//           className="cursor-pointer underline"
//           onClick={() => handleViewImages(dat.images)}
//         >
//           View
//         </div>
//       ),
//     },
//     {
//       title: "Description",
//       dataIndex: "description",
//       key: "description",
//       className: "table-cell",
//       render: (text) => (
//         <Tooltip
//           color="#E8BC0E"
//           className="cursor-pointer"
//           title={
//             <div
//               className="text-black font-roboto-regular text-[16px] cursor-pointer"
//               style={{ maxHeight: "150px", overflowY: "auto" }}
//             >
//               {text || "---"}
//             </div>
//           }
//         >
//           {text ? text.slice(0, 80) + "..." : "---"}
//         </Tooltip>
//       ),
//     },

//     {
//       title: "",
//       key: "action",
//       className: "table-cell",
//       render: (text, record) => (
//         <ActionMenu
//           menuItems={[
//             { label: "Edit", onClick: () => handleEdit(record) },
//             { label: "Delete", onClick: () => handleConfirmDelete(record) },
//           ]}
//         />
//       ),
//     },
//   ];

//   return (
//     <div>
//       <div className="flex items-center justify-between">
//         <h2 className="font-roboto-bold text-24 text-primary-text">Events</h2>
//         <Button
//           size="large"
//           onClick={() => setAddModal(true)}
//           className="font-roboto-medium text-xl home-add-button"
//         >
//           <BiPlus size={20} />
//           Add Event
//         </Button>
//       </div>
//       <Divider className="my-5" />
//       <Spin spinning={deleteRecordSpin}>
//         <Table dataSource={event ? event : []} columns={columns2} />
//       </Spin>

//       <CustomModal title="Add Event" modal={addModal} setModal={setAddModal}>
//         <AddEvents
//           handleSubmit={handleAddData}
//           setModal={setAddModal}
//           loading={addRecordSpin}
//         />
//       </CustomModal>
//       <CustomModal title="Edit Event" modal={editModal} setModal={setEditModal}>
//         <EditEvents
//           handleSubmit={handleEditData}
//           data={selectedEvent}
//           setModal={setEditModal}
//           loading={editRecordSpin}
//         />
//       </CustomModal>
//       <Modal
//         title={
//           <div style={{ display: "flex", alignItems: "center" }}>
//             <FaRegCircleQuestion
//               size={28}
//               style={{ color: "#ff4d4f", marginRight: 10, marginTop: "10px" }}
//             />
//             <span className="text-lg font-roboto-bold">
//               Are you sure you want to delete this information?
//             </span>
//           </div>
//         }
//         open={deleteModal}
//         onCancel={() => setDeleteModal(false)}
//         footer={[
//           <Button
//             key="cancel"
//             onClick={() => setDeleteModal(false)}
//             className="cancel-button text-lg p-5"
//             style={{ marginRight: "0px", fontFamily: "Roboto-Bold" }}
//           >
//             Cancel
//           </Button>,
//           <Button
//             key="confirm"
//             type="primary"
//             onClick={handleDelete}
//             className="save-button text-lg p-5"
//             style={{ fontFamily: "Roboto-Bold" }}
//             loading={deleteRecordSpin} // Spinner inside the delete button
//           >
//             OK
//           </Button>,
//         ]}
//       >
//         <p className="ml-10 mb-5">
//           This information will be deleted permanently.
//         </p>
//       </Modal>
//       <Modal
//         title="Image View"
//         visible={imageModal}
//         footer={null}
//         onCancel={() => setImageModal(false)}
//         width={800}
//       >
//         <Spin spinning={imageLoading} size="large">
//           <Divider className="mt-1 mb-2" />
//           <Carousel
//             ref={carouselRef}
//             afterChange={(current) => setActiveImage(current)}
//             initialSlide={activeImage}
//           >
//             {selectedImages.map((image, index) => (
//               <div key={index}>
//                 <img
//                   src={image}
//                   alt={`Event Image ${index}`}
//                   style={{
//                     width: "100%",
//                     height: "415px",
//                     border: "1px solid #000",
//                   }}
//                 />
//               </div>
//             ))}
//           </Carousel>
//           <div
//             className="thumbnail-container"
//             style={{ display: "flex", overflowX: "auto", marginTop: 10 }}
//           >
//             {selectedImages.map((image, index) => (
//               <img
//                 key={index}
//                 src={image}
//                 alt={`Thumbnail ${index}`}
//                 style={{
//                   width: 120,
//                   height: 120,
//                   margin: "0 5px",
//                   cursor: "pointer",
//                   border: activeImage === index ? "2px solid #1890ff" : "none",
//                 }}
//                 onClick={() => handleThumbnailClick(index)}
//               />
//             ))}
//           </div>
//         </Spin>
//       </Modal>
//     </div>
//   );
// };

// export default EventsTable;

import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Divider,
  Table,
  Modal,
  Carousel,
  Spin,
  Tooltip,
  Pagination,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGetAction,
  DataRequestAction,
} from "../../redux/actions/CommonHttp";
import moment from "moment";
import { BiPlus } from "react-icons/bi";
import AddEvents from "./components/AddEvents";
import CustomModal from "../../components/Modal/CustomModal";
import EditEvents from "./components/EditEvent";
import ActionMenu from "../../components/ActionMenu";
import { resetCrudState } from "../../redux/reducers/crudSlice";
import { FaRegCircleQuestion } from "react-icons/fa6";

const EventsTable = () => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.crud.events);
  const addRecordSpin = useSelector((state) => state.crud.addRecordSpin);
  const editRecordSpin = useSelector((state) => state.crud.editRecordSpin);
  const deleteRecordSpin = useSelector((state) => state.crud.deleteRecordSpin);
  const addRecordSuccess = useSelector((state) => state.crud.addRecordSuccess);
  const eventsData = useSelector((state) => state.crud.events.data);
  const eventsPagination = useSelector(
    (state) =>
      state.crud.events?.pagination || { current: 1, pageSize: 10, total: 0 }
  );

  console.log("-===pagionation", eventsPagination);
  const editRecordSuccess = useSelector(
    (state) => state.crud.editRecordSuccess
  );
  const deleteRecordSuccess = useSelector(
    (state) => state.crud.deleteRecordSuccess
  );

  // Pagination state

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [activeImage, setActiveImage] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    if (addRecordSuccess) {
      setAddModal(false);
      dispatch(resetCrudState());
    }
  }, [addRecordSuccess, dispatch]);

  useEffect(() => {
    if (editRecordSuccess) {
      setEditModal(false);
      dispatch(resetCrudState());
    }
  }, [editRecordSuccess, dispatch]);

  useEffect(() => {
    if (deleteRecordSuccess) {
      setDeleteModal(false);
      dispatch(resetCrudState());
    }
  }, [deleteRecordSuccess, dispatch]);

  useEffect(() => {
    if (eventsPagination && eventsPagination.current) {
      fetchData(eventsPagination.current); // Fetch data for the initial page
    }
  }, [dispatch, eventsPagination.current]);

  // Function to fetch data
  const fetchData = (page) => {
    if (!page) {
      console.error("Page number is undefined!"); // Debugging log
      return;
    }
    console.log("Fetching data for page:", page); // Debugging log

    const params = {
      page: page,
      pageSize: eventsPagination.pageSize,
    };

    dispatch(
      DataGetAction({
        apiName: "getEvents",
        data: params,
        requestType: "fetch",
        recordName: "events",
      })
    );
  };

  // Handle page change
  const handlePageChange = (page) => {
    console.log("Changing to page:", page); // Debugging log

    // Fetch data for the new page
    fetchData(page);
  };

  const handleAddData = (formData) => {
    dispatch(
      DataRequestAction({
        method: "POST",
        apiName: "addEvent",
        data: formData,
        requestType: "addRecord",
        recordName: "events",
      })
    ).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        fetchData(1); // Re-fetch data from the first page after adding a new event
      }
    });
  };

  const handleEdit = (record) => {
    setSelectedEvent(record);
    setEditModal(true);
  };

  const handleEditData = (formData) => {
    dispatch(
      DataRequestAction({
        method: "PUT",
        apiName: "updateEvent",
        data: formData,
        requestType: "updateRecord",
        recordName: "events",
      })
    ).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        fetchData(eventsPagination.current); // Re-fetch data for the current page after editing an event
      }
    });
  };

  const handleDelete = () => {
    if (selectedEvent) {
      dispatch(
        DataRequestAction({
          method: "DELETE",
          apiName: `deleteEvents`,
          data: { id: selectedEvent?._id },
          requestType: "deleteRecord",
          recordName: "events",
        })
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          fetchData(1); // Re-fetch data from the first page after deleting an event
        }
      });
    }
  };

  const handleConfirmDelete = (record) => {
    setSelectedEvent(record);
    setDeleteModal(true);
  };

  const handleViewImages = (images) => {
    setSelectedImages(images);
    setActiveImage(0);
    setImageLoading(true);
    setImageModal(true);

    setTimeout(() => {
      setImageLoading(false);
    }, 1000);
  };

  const handleThumbnailClick = (index) => {
    setActiveImage(index);
    carouselRef.current.goTo(index, true);
  };

  console.log("Events data from Redux:", event);

  // Make sure event data is defined and not empty
  const sortedEvents = Array.isArray(event)
  ? [...event].sort((a, b) => {
      // Sort by date first
      if (new Date(a.date) < new Date(b.date)) return -1;
      if (new Date(a.date) > new Date(b.date)) return 1;

      // If the dates are equal, sort by startTime
      if (a.startTime < b.startTime) return -1;
      if (a.startTime > b.startTime) return 1;

      return 0;
    })
  : [];



  // Debugging log to see sorted events
  console.log("Sorted Events:", sortedEvents);
  const columns2 = [
    {
      title: "ID",
      dataIndex: "eventId",
      key: "eventId",
      className: "table-cell font-semibold",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "table-cell font-semibold",
    },
    {
      title: "Date & Time",
      dataIndex: "startTime",
      className: "table-cell",
      key: "startTime",
      render: (_, record) => {
        const { date, startTime, endTime } = record;

        // Format the date using UTC
        const formattedDate = moment.utc(date).format("DD-MM-YYYY");

        // Conditional rendering for startTime and endTime
        const formattedStartTime =
          startTime &&
          typeof startTime === "string" &&
          startTime.trim() !== "" &&
          !moment(startTime, "HH:mm:ss", true).isValid()
            ? startTime // Show the string directly if it's not a valid time but a valid non-empty string
            : startTime && moment(startTime, "HH:mm:ss", true).isValid()
            ? moment.utc(startTime, "HH:mm:ss").format("hh:mm A") // Format valid times in UTC
            : "---"; // Show "---" for all other cases

        const formattedEndTime =
          endTime &&
          typeof endTime === "string" &&
          endTime.trim() !== "" &&
          !moment(endTime, "HH:mm:ss", true).isValid()
            ? endTime
            : endTime && moment(endTime, "HH:mm:ss", true).isValid()
            ? moment.utc(endTime, "HH:mm:ss").format("hh:mm A")
            : "---";

        return (
          <div>
            <span>{formattedDate}</span>
            <br />
            <span>
              {formattedStartTime} - {formattedEndTime}
            </span>
          </div>
        );
      },
    },

    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
      className: "table-cell",
      render: (text) =>
        text && text.length > 50 ? (
          <Tooltip
            color="#E8BC0E"
            className="cursor-pointer"
            title={
              <div
                className="text-black font-roboto-regular text-[16px] cursor-pointer"
                style={{ maxHeight: "150px", overflowY: "auto" }}
              >
                {text}
              </div>
            }
          >
            {text.slice(0, 50) + "..."}
          </Tooltip>
        ) : (
          <span>{text || "---"}</span>
        ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      className: "table-cell",
      render: (text) =>
        text && text.length > 50 ? (
          <Tooltip
            color="#E8BC0E"
            className="cursor-pointer"
            title={
              <div
                className="text-black font-roboto-regular text-[16px] cursor-pointer"
                style={{ maxHeight: "150px", overflowY: "auto" }}
              >
                {text}
              </div>
            }
          >
            {text.slice(0, 50) + "..."}
          </Tooltip>
        ) : (
          <span>{text || "---"}</span>
        ),
    },
    {
      title: "Images",
      key: "images",
      className: "table-cell",
      render: (dat) => (
        <div
          className="cursor-pointer underline"
          onClick={() => handleViewImages(dat.images)}
        >
          View
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      className: "table-cell",
      render: (text) => (
        <Tooltip
          color="#E8BC0E"
          className="cursor-pointer"
          title={
            <div
              className="text-black font-roboto-regular text-[16px] cursor-pointer"
              style={{ maxHeight: "150px", overflowY: "auto" }}
            >
              {text || "---"}
            </div>
          }
        >
          {text ? text.slice(0, 80) + "..." : "---"}
        </Tooltip>
      ),
    },
    {
      title: "",
      key: "action",
      className: "table-cell",
      render: (text, record) => (
        <ActionMenu
          menuItems={[
            { label: "Edit", onClick: () => handleEdit(record) },
            { label: "Delete", onClick: () => handleConfirmDelete(record) },
          ]}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="font-roboto-bold text-24 text-primary-text">Events</h2>
        <Button
          size="large"
          onClick={() => setAddModal(true)}
          className="font-roboto-medium text-xl home-add-button"
        >
          <BiPlus size={20} />
          Add Event
        </Button>
      </div>
      <Divider className="my-5" />
      <Spin spinning={deleteRecordSpin}>
        <Table
          dataSource={eventsData}
          columns={columns2}
          pagination={false} // Disable internal pagination to use custom Pagination component
        />

        {/* Ant Design Pagination Component */}
      </Spin>
      <Pagination
        className="pt-4 flex justify-end"
        current={eventsPagination.current}
        pageSize={eventsPagination.pageSize}
        total={eventsPagination.total}
        onChange={handlePageChange} // Trigger handlePageChange on page change
        showSizeChanger={false} // Hide page size changer if not needed
      />
      <CustomModal title="Add Event" modal={addModal} setModal={setAddModal}>
        <AddEvents
          handleSubmit={handleAddData}
          setModal={setAddModal}
          loading={addRecordSpin}
        />
      </CustomModal>
      <CustomModal title="Edit Event" modal={editModal} setModal={setEditModal}>
        <EditEvents
          handleSubmit={handleEditData}
          data={selectedEvent}
          setModal={setEditModal}
          loading={editRecordSpin}
        />
      </CustomModal>
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaRegCircleQuestion
              size={28}
              style={{ color: "#ff4d4f", marginRight: 10, marginTop: "10px" }}
            />
            <span className="text-lg font-roboto-bold">
              Are you sure you want to delete this information?
            </span>
          </div>
        }
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setDeleteModal(false)}
            className="cancel-button text-lg p-5"
            style={{ marginRight: "0px", fontFamily: "Roboto-Bold" }}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={handleDelete}
            className="save-button text-lg p-5"
            style={{ fontFamily: "Roboto-Bold" }}
            loading={deleteRecordSpin}
          >
            OK
          </Button>,
        ]}
      >
        <p className="ml-10 mb-5">
          This information will be deleted permanently.
        </p>
      </Modal>
      <Modal
        title="Image View"
        visible={imageModal}
        footer={null}
        onCancel={() => setImageModal(false)}
        width={800}
      >
        <Spin spinning={imageLoading} size="large">
          <Divider className="mt-1 mb-2" />
          <Carousel
            ref={carouselRef}
            afterChange={(current) => setActiveImage(current)}
            initialSlide={activeImage}
          >
            {selectedImages.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Event Image ${index}`}
                  style={{
                    width: "100%",
                    height: "415px",
                    border: "1px solid #000",
                  }}
                />
              </div>
            ))}
          </Carousel>
          <div
            className="thumbnail-container"
            style={{ display: "flex", overflowX: "auto", marginTop: 10 }}
          >
            {selectedImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                style={{
                  width: 120,
                  height: 120,
                  margin: "0 5px",
                  cursor: "pointer",
                  border: activeImage === index ? "2px solid #1890ff" : "none",
                }}
                onClick={() => handleThumbnailClick(index)}
              />
            ))}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default EventsTable;
