import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import { Card } from "antd";

const AreaGraph = ({ ProductsSalesGraph }) => {
  return (
    <Card
      title={<span className="text-2xl font-roboto-bold">Monthly Drivers Count</span>}
      bordered={false}
      className="w-full h-[300px] mx-auto drop-shadow-xl "
      bodyStyle={{ padding: '16px' }}
    >
      <ResponsiveContainer height={185}>
        <AreaChart
          data={ProductsSalesGraph}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis dataKey={"month"} tick={{ fontSize: 12, fill: '#000' }} />
          <defs>
            <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#F7E600" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ffffff" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <Area
            dataKey={"totalUsers"}
            strokeWidth={2}
            stackId="2"
            stroke="#000000"
            fill="url(#color15)"
            fillOpacity={1}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default AreaGraph;
