import React from 'react'

const User = () => {
    return (
        <svg className=' mr-3' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6596 14.3064C13.0372 13.3511 12.1989 12.5447 12.1989 10.8181C12.1989 9.78192 13.0582 9.11038 13.351 8.22235C13.644 7.3343 13.8134 6.2829 13.9542 5.51808C14.0951 4.75327 14.151 4.45745 14.2276 3.64255C14.3213 2.62553 13.6404 0 10 0C6.36065 0 5.67765 2.62553 5.7734 3.64255C5.85 4.45745 5.90627 4.7533 6.0468 5.51808C6.18735 6.28288 6.35513 7.33423 6.64785 8.22235C6.94055 9.11045 7.80102 9.78192 7.80102 10.8181C7.80102 12.5447 6.96272 13.3511 4.34038 14.3064C1.70852 15.2638 0 16.208 0 16.875V20H20V16.875C20 16.2091 18.2904 15.2649 15.6596 14.3064Z" fill="currentColor" />
        </svg>

    )
}

export default User
