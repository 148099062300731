import { Button, Divider, Form, Input, Spin } from "antd";
import React, { useEffect } from "react";

const EditTrafficData = ({ record, handleSubmit, setModal, loading }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    }
  }, [record]);

  const onFinish = (values) => {
    const formData = { ...values, id: record._id };
    handleSubmit(formData);
    form.resetFields();
  };

  const handleCancel = () => {
    setModal(false);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Form form={form} name="editTrafficData" onFinish={onFinish} autoComplete="off" layout="vertical">
          <Form.Item
            label="Site ID"
            name="siteId"
            rules={[
              {
                required: true,
                message: "Please input the Site ID!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Site ID here..." disabled />
          </Form.Item>

          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Please input the Site ID!",
              },
            ]}
          >
            <Input size="large"  disabled />
          </Form.Item>

          <Form.Item
            label="From Coordinates"
            name={["from", "lat"]}
            rules={[
              {
                required: true,
                message: "Please input the latitude!",
              },
            ]}
          >
            <Input size="large" placeholder="Latitude" disabled />
          </Form.Item>

          <Form.Item
            name={["from", "long"]}
            rules={[
              {
                required: true,
                message: "Please input the longitude!",
              },
            ]}
          >
            <Input size="large" placeholder="Longitude" disabled />
          </Form.Item>

          <Form.Item
            label="To Coordinates"
            name={["to", "lat"]}
            rules={[
              {
                required: true,
                message: "Please input the latitude!",
              },
            ]}
          >
            <Input size="large" placeholder="Latitude" disabled />
          </Form.Item>

          <Form.Item
            name={["to", "long"]}
            rules={[
              {
                required: true,
                message: "Please input the longitude!",
              },
            ]}
          >
            <Input size="large" placeholder="Longitude" disabled />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
          >
            <Input.TextArea rows={5} placeholder="Enter description here..." />
          </Form.Item>

          <Divider style={{ marginBottom: 20 }} />

          <div className="flex justify-end">
            <Button
              type="primary"
              className="cancel-button font-roboto-bold text-lg p-5"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="save-button font-roboto-bold text-lg p-5"
              htmlType="submit"
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default EditTrafficData;
