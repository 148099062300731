


import React, { useState } from "react";
import { Upload, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const UploadComponent = ({
  fileList = [],
  setFiles,
  setImagesUrls,
  eventId,
  setStagedFiles,
  setStagedDeletions,
  maxUpload
}) => {
  const [uploading, setUploading] = useState(false);

  const handleFileAdd = ({ file }) => {
    setUploading(true);
  
    // Check if current file count exceeds maxUpload
    if (fileList.length >= maxUpload) {
      message.error(`You can only upload up to ${maxUpload} file(s).`);
      setUploading(false);
      return;
    }
  
    // Generate a unique file name using a microsecond timestamp
    const microsecondsTimestamp = Date.now() * 1000;
    const uniqueFileName = `${microsecondsTimestamp}-${file.name}`; // Prepend the timestamp to the file name
  
    // Create a local URL for preview purposes
    const localUrl = URL.createObjectURL(file);
  
    // Add the file to the staged files for future upload
    const stagedFile = {
      uid: microsecondsTimestamp.toString(),
      name: uniqueFileName,
      status: "staged", // Mark as staged, not yet uploaded
      url: localUrl, // Use local URL for preview
      file, // Store the file object for later use
    };
  
    setStagedFiles((prevFiles) => [...prevFiles, stagedFile]); // Add to staged files
    setFiles((prevList) => [...prevList, stagedFile]); // Update the display
    setUploading(false);
  };
  

  // Handle file removal from the staging area
  const handleRemove = (file) => {
    // Stage the file for deletion instead of deleting immediately
    setStagedDeletions((prevDeletions) => [...prevDeletions, file]);

    // Update the file list
    setFiles((prevList) => prevList.filter((item) => item.uid !== file.uid));

    // Update the image URLs, ensuring prevUrls is treated as an array
    setImagesUrls((prevUrls) => {
      const urlsArray = Array.isArray(prevUrls) ? prevUrls : [prevUrls];
      return urlsArray.filter((url) => url !== file.url);
    });
  };


  return (
    <Upload
      customRequest={handleFileAdd}
      onRemove={handleRemove}
      fileList={fileList} // Ensures pre-filled data is displayed
      listType="picture-card"
      onPreview={(file) => window.open(file.url)} // Opens the file in a new tab
      maxCount={typeof maxUpload === 'number' ? maxUpload : undefined} // Apply restriction only if maxUpload is a number
    >
      <Spin
        spinning={uploading}
        tip="Uploading..."
        style={{ color: "#E8BC0E" }}
      >
        <div>
          <UploadOutlined style={{ fontSize: 24, color: "#000" }} />
          <div style={{ marginTop: 8, color: "#000" }}>Upload</div>
        </div>
      </Spin>
    </Upload>
  );
};

export default UploadComponent;
