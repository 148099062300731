

import { Button, Divider, Form, Input } from "antd";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";

const AddBroads = ({ handleSubmit, setModal }) => {
  const [form] = Form.useForm();
  const addRecordSpin = useSelector((state) => state.crud.addRecordSpin); // Use the Redux state for spinner

  const onFinish = (values) => {
    let obj = {
      ...values,
    };
    handleSubmit(obj);
    form.resetFields(); // Reset fields after submission
  };

  const handleCancel = () => {
    form.resetFields(); // Reset fields on cancel
    setModal(false); // Close the modal
  };

  return (
    <div>
      <Form form={form} name="addBanner" onFinish={onFinish} autoComplete="off" layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Please input the broadcast title!",
            },
          ]}
        >
          <Input size="large" showCount maxLength={50} placeholder="Enter title here..." />
        </Form.Item>

        <Form.Item
          label="Message"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input the description!",
            },
          ]}
        >
          <TextArea rows={10} placeholder="Enter description here..." />
        </Form.Item>

        <Divider style={{ marginBottom: 20 }} />

        <div className="flex justify-end">
          <Button type="primary" className="cancel-button font-roboto-bold text-lg p-5" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="save-button font-roboto-bold text-lg p-5"
            htmlType="submit"
            style={{ backgroundColor: "yellow", color: "black" }}
            loading={addRecordSpin} // Add the spinner here
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddBroads;
