import React from 'react';
import { Avatar, Button } from 'antd';
import { MailOutlined, PhoneOutlined, CalendarOutlined } from '@ant-design/icons';
import background from '../../../assets/images/profileBack.png'; // Replace with your correct image path
import { IoPersonOutline } from 'react-icons/io5';

const ProfileModal = ({ profileData, onClose }) => {
  return (
    <div style={{
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        position: 'relative',
        width: '472px',
        borderRadius: '16px',
        overflow: 'hidden',
        textAlign: 'center',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <div style={{
          height: '200px',
          position: 'relative',
        }}>
          <Avatar
            src={profileData?.profileImage}
            size={160}
            style={{
              border: '3px solid #fff',
              position: 'absolute',
              bottom: '-45px',
              left: '51%',
              transform: 'translateX(-50%)',
              zIndex: 1,
            }}
          />
        </div>
        <div style={{ padding: '60px 20px 20px' }} className='flex flex-col items-center'>
          <p className="font-roboto-bold text-2xl flex items-center justify-center gap-2 capitalize" style={{ marginBottom: '8px' }}><IoPersonOutline /> {profileData.fullName}</p>
          <p className="font-roboto-regular text-lg capitalize" style={{ marginBottom: '8px' }}>({profileData.driverType})</p>
          <p className="font-roboto-regular text-lg" style={{ marginBottom: '8px' }}><MailOutlined /> {profileData.email}</p>
          <p className="font-roboto-regular text-lg" style={{ marginBottom: '8px' }}><PhoneOutlined /> {profileData.phoneNumber}</p>
          <p className="font-roboto-regular text-lg" style={{ marginBottom: '8px' }}><CalendarOutlined /> {new Date(profileData?.dateOfBirth).toDateString()}</p>
          <Button size='large' className='px-10 font-roboto-medium' type="primary" onClick={onClose} style={{ backgroundColor: '#000', color: '#FFD700', marginLeft: "350px" }}>
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
