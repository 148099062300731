import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  TimePicker,
  message,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import UploadComponent from "../../../components/UploadComponent/UploadComponent";

const EditEvents = ({ handleSubmit, setModal, data, loading }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState(
    data?.images.map((url, index) => ({
      uid: index.toString(),
      name: url.split("/").pop(),
      status: "done",
      url,
    }))
  );
  const [imagesUrls, setImagesUrls] = useState(data?.images);
  const [stagedFiles, setStagedFiles] = useState([]); // Temporary storage for new uploads
  const [stagedDeletions, setStagedDeletions] = useState([]); // Temporary storage for deletions

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (data) {
      console.log("===data is", data);
      form.setFieldsValue({
        name: data?.name,
        venue: data?.venue,
        address: data?.address,
        eventUrl: data?.eventUrl,
        description: data?.description,
        date: data?.date ? moment(data?.date, "YYYY-MM-DD") : null,
        startTime: data?.startTime ? moment(data?.startTime, "HH:mm A") : null,
        endTime: data?.endTime ? moment(data?.endTime, "HH:mm A") : null,
      });
     
      const fileList = data?.images.map((url, index) => ({
        uid: index.toString(),
        name: url.split("/").pop(),
        status: "done",
        url,
      }));
      setFiles(fileList);
      setImagesUrls(data?.images);
    }
  }, [data]);

  const commitChanges = async () => {
    const token = localStorage.getItem("token");
  
    let updatedImagesUrls = [...imagesUrls];
  
    for (const stagedFile of stagedFiles) {
      const { name, file: actualFile } = stagedFile;
  
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ROOT_URL}/generatePresignedURL`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
            body: JSON.stringify({
              fileName: name,
              fileType: actualFile.type,
            }),
          }
        );
  
        if (!response.ok) {
          message.error("Failed to get presigned URL");
          console.error("Failed to get presigned URL", await response.text());
          continue;
        }
  
        const { url } = await response.json();
  
        // Upload the file to S3 using the presigned URL
        try {
          const uploadResponse = await fetch(url, {
            method: "PUT",
            body: actualFile,
            headers: {
              "Content-Type": actualFile.type,
            },
          });
  
          if (!uploadResponse.ok) {
            message.error("Upload failed");
            console.error(
              "Error uploading file to S3",
              await uploadResponse.text()
            );
            continue;
          }
  
         // message.success("Upload successful");
  
          // Trim the URL to remove query parameters
          const trimmedUrl = url.split("?")[0]; // Removes everything after '?'
  
          // Add the trimmed URL to updatedImagesUrls
          updatedImagesUrls = [...updatedImagesUrls, trimmedUrl];
        } catch (error) {
          console.error("Error uploading file:", error);
          message.error("Upload failed");
        }
      } catch (error) {
        console.error("Error during presigned URL generation or upload:", error);
      }
    }
  
    for (const file of stagedDeletions) {
      const apiEndpoint = `${process.env.REACT_APP_ROOT_URL}/deleteFileFroms3`;
      const bodyPayload = {
        fileKey: file.name,
        eventId: data?._id,
        imageUrl: file.url,
      };
  
      try {
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(bodyPayload),
        });
  
        if (!response.ok) {
          const errorText = await response.text();
         // message.error(`Failed to delete file: ${errorText}`);
          console.error("Error deleting file from S3 or DB", errorText);
        } else {
         // message.success("File removed successfully");
  
          // Remove the file from updatedImagesUrls list
          updatedImagesUrls = updatedImagesUrls.filter((url) => url !== file.url);
        }
      } catch (err) {
        console.error("Error deleting file:", err);
       // message.error("Error deleting file");
      }
    }
  
    // Update the main state to reflect all changes
    setImagesUrls(updatedImagesUrls);
    setStagedFiles([]);
    setStagedDeletions([]);
  
    return updatedImagesUrls; // Return the updated URLs for use in onFinish
  };
  
  
  const onFinish = async (values) => {

    setButtonLoading(true); // Start the button loading
    const start = values.startTime ? values.startTime.format("HH:mm A") : null;
    const end = values.endTime ? values.endTime.format("HH:mm A") : null;
    const date = values.date ? values.date.format("YYYY-MM-DD") : null;
  
    // Wait for all changes to complete and get the updated URLs
    const updatedImages = await commitChanges();
  
    // Update the form data to include the latest images URLs
    const obj = {
      ...values,
      date,
      startTime: start,
      endTime: end,
      type: "event",
      source: "admin",
      images: updatedImages, // Use the latest image URLs
      id: data?._id,
    };
  
    // Call the handleSubmit function with the updated data
    handleSubmit(obj);

      // Stop the button loading after 5 seconds
    setTimeout(() => {
      setButtonLoading(false);
    }, 5000);
  };
  

  const handleCancel = () => {
    // Discard all changes
    setStagedFiles([]);
    setStagedDeletions([]);
    setFiles(
      data?.images.map((url, index) => ({
        uid: index.toString(),
        name: url.split("/").pop(),
        status: "done",
        url,
      }))
    );
    setImagesUrls(data?.images);
    setModal(false);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Title"
            name="name"
            rules={[
              { required: true, message: "Please input the event title!" },
            ]}
          >
            <Input
              size="large"
              maxLength={50}
              showCount
              placeholder="Enter title here..."
            />
          </Form.Item>

          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true, message: "Please select the date!" }]}
          >
            <DatePicker
              format="DD-MM-YYYY"
              className="w-full"
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
              placeholder="Select Date"
            />
          </Form.Item>

          <Form.Item
            label="Start Time"
            name="startTime"
            rules={[
              { required: true, message: "Please select the start time!" },
            ]}
          >
            <TimePicker
              format="hh:mm A"
              className="w-full"
              placeholder="Select start time"
            />
          </Form.Item>

          <Form.Item label="End Time" name="endTime">
            <TimePicker
              format="hh:mm A"
              className="w-full"
              placeholder="Select end time (optional)"
            />
          </Form.Item>

          <Form.Item
            label="Venue"
            name="venue"
            rules={[
              { required: true, message: "Please input the event venue!" },
            ]}
          >
            <Input size="large" placeholder="Enter venue here..." />
          </Form.Item>

          <Form.Item label="Address" name="address">
            <TextArea rows={4} placeholder="Enter address here..." />
          </Form.Item>

          <Form.Item
            label={
              <span className="font-roboto-medium ml-3 text-[17px]">
                Url / Link
              </span>
            }
            name="eventUrl"
          >
            <Input size="large" disabled placeholder=" www.example.com" />
          </Form.Item>

          <Form.Item
            label="Event Images"
            name="images"
            rules={[
              {
                required: files.length === 0,
                message: "Please upload event images!",
              },
            ]}
          >
            <UploadComponent
              fileList={files}
              setFiles={setFiles}
              setImagesUrls={setImagesUrls}
              eventId={data?._id}
              setStagedFiles={setStagedFiles}
              setStagedDeletions={setStagedDeletions}
            />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input the event description!",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Enter event description here..." />
          </Form.Item>

          <Divider style={{ marginBottom: 20 }} />

          <div className="flex justify-end">
            <Button
              type="primary"
              className="cancel-button font-roboto-bold text-lg p-5"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="save-button font-roboto-bold text-lg p-5"
              htmlType="submit"
              style={{ backgroundColor: "yellow", color: "black" }}
              loading={buttonLoading} // Use local loading state for the button
            >
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default EditEvents;
