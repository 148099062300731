import { Button, DatePicker, Divider, Form, Input, TimePicker, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import UploadComponent from "../../../components/UploadComponent/UploadComponent";

const AddEvents = ({ handleSubmit, setModal, loading }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [stagedFiles, setStagedFiles] = useState([]); // Staged files for new uploads
  const [stagedDeletions, setStagedDeletions] = useState([]); // Staged files for deletion
  const [uploading, setUploading] = useState(false);
  const options = { hour: "2-digit", minute: "2-digit", hour12: true };

  const commitChanges = async () => {
    const token = localStorage.getItem("token");
    let updatedImagesUrls = [...imageUrls]; // Use a local copy to track changes

    for (const stagedFile of stagedFiles) {
      const { name, file: actualFile } = stagedFile;

      try {
        const response = await fetch(`${process.env.REACT_APP_ROOT_URL}/generatePresignedURL`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            fileName: name,
            fileType: actualFile.type,
          }),
        });

        if (!response.ok) {
          message.error("Failed to get presigned URL");
          console.error("Failed to get presigned URL", await response.text());
          continue;
        }

        const { url } = await response.json();

        // Upload the file to S3 using the presigned URL
        try {
          const uploadResponse = await fetch(url, {
            method: "PUT",
            body: actualFile,
            headers: {
              "Content-Type": actualFile.type,
            },
          });

          if (!uploadResponse.ok) {
            message.error("Upload failed");
            console.error("Error uploading file to S3", await uploadResponse.text());
            continue;
          }

         

          // Trim the URL to remove query parameters
          const trimmedUrl = url.split("?")[0]; // Removes everything after '?'

          // Add the trimmed URL to updatedImagesUrls
          updatedImagesUrls = [...updatedImagesUrls, trimmedUrl];
        } catch (error) {
          console.error("Error uploading file:", error);
          message.error("Upload failed");
        }
      } catch (error) {
        console.error("Error during presigned URL generation or upload:", error);
      }
    }

    setImageUrls(updatedImagesUrls);
    setStagedFiles([]);
    setStagedDeletions([]); // Clear staged deletions after processing
    return updatedImagesUrls; // Return the updated URLs
  };

  const onFinish = async (values) => {
    console.log("+++++++Value is", values);
    let start = values.startTime ? new Date(values.startTime).toLocaleTimeString([], options) : null;
    let end = values.endTime ? new Date(values.endTime).toLocaleTimeString([], options) : null;
    let date = values.date ? values.date.format("YYYY-MM-DD") : null; // Convert date to string in YYYY-MM-DD format

    const updatedImages = await commitChanges(); // Wait for all uploads to complete

    let obj = {
      ...values,
      date: date,
      startTime: start,
      endTime: end,
      type: "event",
      source: "admin",
      images: updatedImages.length > 0 ? updatedImages : ["https://shorturl.at/FMR7q"], // Use updated images
    };

    handleSubmit(obj);
    form.resetFields();
    setFiles([]);
    setImageUrls([]);
  };

  const handleCancel = () => {
    form.resetFields();
    setFiles([]);
    setImageUrls([]);
    setStagedFiles([]);
    setStagedDeletions([]);
    setModal(false);
  };

  const handleUpload = (file) => {
    setFiles((prev) => [...prev, file]);
    setStagedFiles((prev) => [...prev, { name: file.name, file }]);

    // Trigger validation for images field
    form.setFieldsValue({ images: [...files, file] });
    form.validateFields(["images"]);
  };

  const handleRemove = (file) => {
    const newFiles = files.filter((f) => f.uid !== file.uid);
    setFiles(newFiles);

    // Add to staged deletions if needed
    setStagedDeletions((prev) => [...prev, file]);

    // Trigger validation for images field
    form.setFieldsValue({ images: newFiles });
    form.validateFields(["images"]);
  };


  return (
    <div>
      <Spin spinning={loading || uploading}>
        <Form form={form} name="basic" onFinish={onFinish} autoComplete="off" layout="vertical">
          <Form.Item
            label="Title"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input the event title!",
              },
            ]}
          >
            <Input size="large" maxLength={50} showCount placeholder="Enter title here..." />
          </Form.Item>

          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
                message: "Please select the date!",
              },
            ]}
          >
            <DatePicker
              className="w-full"
              format={"DD-MM-YYYY"}
              disabledDate={(current) => current && current < moment().startOf("day")}
              placeholder="Select Date"
            />
          </Form.Item>

          <Form.Item
            label="Start Time"
            name="startTime"
            rules={[
              {
                required: true,
                message: "Please select the start time!",
              },
            ]}
          >
            <TimePicker showTime={{ format: "hh:mm A" }} format="hh:mm A" className="w-full" placeholder="Select start time" />
          </Form.Item>

          <Form.Item
            label="End Time (Optional)"
            name="endTime"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const startTime = getFieldValue("startTime");
                  if (!value || startTime.isBefore(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("End time must be greater than start time!"));
                },
              }),
            ]}
          >
            <TimePicker
              showTime={{ format: "hh:mm A" }}
              format="hh:mm A"
              className="w-full"
              placeholder="Select end time (Optional)"
            />
          </Form.Item>

          <Form.Item label="Venue" name="venue" rules={[{ required: true, message: "Please input the event venue!" }]}>
            <Input size="large" placeholder="Enter venue here..." />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
          >
            <TextArea rows={4} placeholder="Enter address here..." />
          </Form.Item>

          <Form.Item
            label={<span className="font-roboto-medium ml-3 text-[17px]">Url / Link</span>}
            name="eventUrl"
            rules={[
              {
                pattern: /[a-zA-Z0-9-]+\./,
                message: "Please enter a link in the format www.example.com",
              },
            ]}
          >
            <Input addonBefore="https://"  size="large" placeholder="www.example.com" />
          </Form.Item>

          <Form.Item
            label="Event Images"
            name="images"
          
          >
            <UploadComponent
              fileList={files}
              setFiles={setFiles}
              setImagesUrls={setImageUrls}
              setStagedFiles={setStagedFiles}
              setStagedDeletions={setStagedDeletions} // Pass the setStagedDeletions function
              onUpload={handleUpload} // Pass the handleUpload function
              onRemove={handleRemove} // Pass the handleRemove function
            />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input the event description!",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Enter event description here..." />
          </Form.Item>

          <Divider style={{ marginBottom: 20 }} />

          <div className="flex justify-end">
            <Button type="primary" className="cancel-button font-roboto-bold text-lg p-5" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="save-button font-roboto-bold text-lg p-5"
              htmlType="submit"
              style={{ backgroundColor: "yellow", color: "black" }}
              loading={loading || uploading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default AddEvents;
