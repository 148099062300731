export const LoginSuccess = (data) => {
  let authUserData = null;
  localStorage.setItem("userInfo", JSON.stringify(data));
  localStorage.setItem("token", data.token);
  authUserData = data;
  return authUserData;
};
export const addRecord = (oldData, newData) => {
  if (oldData && oldData.length > 0) {
    return [newData, ...oldData];
  } else {
    return [newData];
  }
};
export const updateRecord = (oldData, newData) => {
  let updateArray = [];
  if (oldData && oldData.length > 0) {
    oldData.map((item) => {
      if (item._id === newData._id) {
        updateArray.push(newData);
      } else {
        updateArray.push(item);
      }
    });
  }
  return updateArray;
};
export const deleteRecord = (oldData, newData) => {
  let deleteArray = [];
  if (oldData && oldData.length > 0) {
    oldData.map((item) => {
      if (item._id !== newData._id) {
        deleteArray.push(item);
      }
    });
  }
  return deleteArray;
};
