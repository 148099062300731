import React, { useEffect, useState } from "react";
import { Button, Divider, Table, Tag, Avatar, Input } from "antd";
import ActionMenu from "../../components/ActionMenu";
import ProfileModal from "./components/ProfileModal";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/CommonHttp";
import CustomModal from "../../components/Modal/CustomModal";
import ResetPasswordForm from "./components/ResetPasswordForm";

const UsersTable = () => {
  const [visible, setVisible] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const drivers = useSelector((state) => state.crud.drivers.data);
  console.log("==drivers", drivers);

  useEffect(() => {
    dispatch(DataGetAction({ apiName: "getAllUser", data: "", requestType: "fetch", recordName: "drivers" }));
  }, []);



  const handleViewProfile = (record) => {
    setSelectedProfile(record);
  };

  const handleCloseProfileView = () => {
    setSelectedProfile(null);
  };

  const changeStatus = (record) => {
    dispatch(
      DataRequestAction({
        method: "PUT",
        apiName: "changeStatus",
        data: { id: record?._id, status: record?.status === 'active' ? 'block' : 'active' },
        requestType: "updateRecord",
        recordName: "drivers",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.responseData?.status === "SUCCESS") {
          dispatch(
            DataGetAction({
              apiName: "getAllUser",
              data: "",
              requestType: "fetch",
              recordName: "drivers",
            })
          );
        }
      })
      .catch((error) => {
        console.error("Status change failed:", error);
      });
  };

  const deleteUser = (record) => {
    dispatch(
      DataRequestAction({
        method: "DELETE",
        apiName: "deleteUser",
        data: { id: record?._id },
        requestType: "deleteRecord",
        recordName: "del",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.responseData?.status === "SUCCESS") {
          dispatch(
            DataGetAction({
              apiName: "getAllUser",
              data: "",
              requestType: "fetch",
              recordName: "drivers",
            })
          );
        }
      })
      .catch((error) => {
        console.error("Status change failed:", error);
      });
  };


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: "table-cell font-semibold",
      render: (text, record, index) => (
        <div>
          <span className=" font-roboto-normal text-base">{index + 1}</span>
        </div>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) => (
        <div className="flex items-center">
          <Avatar className=" border bg-gray-50" size={50} src={record?.profileImage} />
          <span className="ml-3 font-roboto-medium text-lg capitalize">{text}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "table-cell",
      width: "250px",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      className: "table-cell",
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      className: "table-cell",
      render: (text, record) => (
        <div>
          {new Date(record?.dateOfBirth).toDateString()}
        </div>
      )
    },
    {
      title: "Type",
      dataIndex: "driverType",
      key: "driverType",
      className: "table-cell",
      render: (text, record) => (
        <div className=" capitalize">
          {record?.driverType}
        </div>
      )
    },
    {
      title: "Last Activity",
      dataIndex: "lastActivity",
      key: "lastActivity",
      className: "table-cell",
      render: (text, record) => (
        <div>
          {new Date(record?.lastActivity).toLocaleTimeString()}
        </div>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10px",
      className: "table-cell",
      render: (status) => (
        <Tag className="text-sm px-6 py-1 rounded capitalize" color={status === "active" ? "green" : "red"}>
          {status}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      className: "table-cell",
      fixed: 'right',
      render: (text, record) => <ActionMenu menuItems={[
        { label: "View", onClick: () => handleViewProfile(record) },
        { label: record?.status == 'active' ? "Block" : "Active", onClick: () => { changeStatus(record) } },
        { label: "Reset Password", onClick: () => { setVisible(true); setSelectedUser(record) } },
        { label: "Delete", onClick: () => { deleteUser(record) } }
      ]} />,
    },
  ];

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredDrivers = (Array.isArray(drivers) ? drivers : []).filter((driver) =>
    driver.fullName.toLowerCase().includes(searchInput.toLowerCase()) ||
    driver.email.toLowerCase().includes(searchInput.toLowerCase()) ||
    driver.phoneNumber.toLowerCase().includes(searchInput.toLowerCase())
  );


  return (
    <div className="">
      <div className="flex items-center justify-between">
        <h2 className="font-roboto-bold text-20 text-primary-text">Drivers</h2>
        <Input className=" w-fit" size="large" placeholder="Name/Email/Phone" onChange={handleSearch} />
      </div>
      <Divider className="my-5" />
      <Table
        dataSource={filteredDrivers ? filteredDrivers : []}
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content' }} // Enable horizontal scroll for smaller screens
      />
      {selectedProfile && <ProfileModal profileData={selectedProfile} onClose={handleCloseProfileView} />}
      <CustomModal title="Reset Password" modal={visible} setModal={setVisible}>
        <ResetPasswordForm selectedUser={selectedUser} setModal={setVisible} />
      </CustomModal>
    </div>
  );
};

export default UsersTable;
