import React, { useEffect, useState } from "react";
import { Avatar, Input, Form, Divider, Modal } from "antd";
import { EditOutlined, KeyOutlined } from "@ant-design/icons";
import { FaEdit } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import default styles
import EditProfileForm from "./components/EditProfileModal";
import ResetPasswordForm from "./components/ResetPasswordModal"; // Update this import
import { useDispatch, useSelector } from "react-redux";
import { getAuthUser, updateUserProfile } from "../../redux/reducers/authSlice"; // Ensure updateUserProfile is imported

const Index = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.authUser);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAuthUser());
  }, [dispatch]);

  useEffect(() => {
    if (authUser) {
      form.setFieldsValue({
        fullName: authUser.fullName,
        email: authUser.email,
        phoneNumber: authUser.phoneNumber,
      });
    }
  }, [authUser, form]);

  const [isEditProfileModalVisible, setIsEditProfileModalVisible] = useState(false);
  const [isResetPasswordModalVisible, setIsResetPasswordModalVisible] = useState(false);

  const showEditProfileModal = () => {
    setIsEditProfileModalVisible(true);
  };

  const handleEditProfileModalClose = () => {
    setIsEditProfileModalVisible(false);
    dispatch(getAuthUser());
  };

  const showResetPasswordModal = () => {
    setIsResetPasswordModalVisible(true);
  };

  const handleResetPasswordModalClose = () => {
    setIsResetPasswordModalVisible(false);
  };

  const [phone, setPhone] = useState(authUser?.phoneNumber);

  return (
    <div>
      <h2 className="font-roboto-bold text-24 text-primary-text">Settings</h2>
      <Divider />
      <div className="flex flex-col items-start p-1">
        <div className="bg-white shadow-md p-4 rounded-lg w-full max-w-md">
          <div className="flex items-center justify-between">
            <Avatar
              className=" bg-gray-50 border"
              shape="square"
              size={120}
              src={
                authUser && authUser?.profileImage
                  ? authUser?.profileImage
                  : "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png"
              }
            />
            <div className="ml-4 flex flex-col">
              <FaEdit className="text-3xl font-roboto-regular mb-2 cursor-pointer" onClick={showEditProfileModal} />
              <KeyOutlined className="text-3xl font-roboto-regular cursor-pointer" onClick={showResetPasswordModal} />
            </div>
          </div>
          <Form layout="vertical" className="mt-4" initialValues={authUser} form={form}>
            <Form.Item name="fullName" label={<span className="font-roboto-medium text-[16px]">Full Name</span>}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="email" label={<span className="font-roboto-medium text-[16px]">Email</span>}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="phoneNumber" label={<span className="font-roboto-medium text-[16px]">Phone Number</span>}>
              <PhoneInput
                country={"gb"} // Set default country to UK
                value={phone} // Set the current phone state
                onChange={setPhone} // Update phone state on change
                disabled
                inputStyle={{ background: "#0101010D", width: "100%" }}
              />
            </Form.Item>
          </Form>
        </div>
      </div>

      <Modal
        title="Edit Profile"
        visible={isEditProfileModalVisible}
        onCancel={handleEditProfileModalClose}
        footer={null} // Hides default modal footer to use form buttons
      >
        <EditProfileForm setModal={setIsEditProfileModalVisible} loading={false} />
      </Modal>

      <Modal
        title="Reset Password"
        visible={isResetPasswordModalVisible}
        onCancel={handleResetPasswordModalClose}
        footer={null} // Hides default modal footer to use form buttons
      >
        <ResetPasswordForm setModal={setIsResetPasswordModalVisible} loading={false} />
      </Modal>
    </div>
  );
};

export default Index;
