import React, { useEffect, useState } from "react";
import AreaGraph from "./components/AreaGraph"; // Make sure to adjust the path accordingly
import NotificationCard from "./components/NotificationCard";
import StatCard from "./components/StatCard";
import graphImage from "../../assets/images/g-1.png";
import graphImage2 from "../../assets/images/g-2.png";
import graphImage3 from "../../assets/images/g-3.png";
import graphImage4 from "../../assets/images/g-4.png";
import RecentDriversCard from "./components/DashboardDriversTable";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.crud.dashboard?.data);
  const notifications = useSelector((state) => state.crud.notifications?.data); // Notifications data from Redux
  console.log("dashboard", dashboard);
  console.log("dashboard12", notifications);

  const [yearData, setYearData] = useState([]);

  useEffect(() => {
    dispatch(
      DataGetAction({
        apiName: "getDashboardData",
        data: "",
        requestType: "fetch",
        recordName: "dashboard",
      })
    );

    dispatch(
      DataGetAction({
        apiName: "getNotifications",
        data: "",
        requestType: "fetch",
        recordName: "notifications",
      })
    );
  }, [dispatch]);

  let yearlyData = [
    { month: "Jan", totalUsers: 0 },
    { month: "Feb", totalUsers: 1800 },
    { month: "Mar", totalUsers: 1100 },
    { month: "Apr", totalUsers: 2200 },
    { month: "May", totalUsers: 1700 },
    { month: "Jun", totalUsers: 0 },
    { month: "Jul", totalUsers: 3000 },
    { month: "Aug", totalUsers: 2300 },
    { month: "Sep", totalUsers: 1900 },
    { month: "Oct", totalUsers: 2800 },
    { month: "Nov", totalUsers: 2400 },
    { month: "Dec", totalUsers: 3000 },
  ];

  useEffect(() => {
    if (
      dashboard &&
      dashboard.createdUsers &&
      dashboard.createdUsers.length > 0
    ) {
      yearlyData.forEach((data, index) => {
        const monthIndex = index + 1; // Months are 1-based in createdUsers
        const userCount =
          dashboard?.createdUsers?.find((item) => item.month === monthIndex)
            ?.count || 0;
        data.totalUsers = userCount;
      });
      setYearData(yearlyData);
    }
  }, [dashboard]);

  return (
    <div>
      <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
        <StatCard
          backgroundColor="#5EB7ED"
          graphImage={graphImage}
          title="Total Drivers"
          count={dashboard?.totalUsers}
        />
        <StatCard
          backgroundColor="#aad437"
          graphImage={graphImage2}
          title="Active Drivers"
          count={dashboard?.activeUsers}
        />
        <StatCard
          backgroundColor="#FBB35F"
          graphImage={graphImage3}
          title="Upcoming Events"
          count={dashboard?.comingEvents}
        />
        <StatCard
          backgroundColor="#70D5B5"
          graphImage={graphImage4}
          title="Total Banners"
          count={dashboard?.bannerCount}
        />
        {/* You can add more StatCards with different props */}
      </div>
      <div className="flex flex-col gap-5 md:flex-row">
        <div className="w-[100%] md:w-[60%]">
          <AreaGraph ProductsSalesGraph={yearData} />
        </div>
        <div className="w-[100%] md:w-[40%]">
          <NotificationCard notifications={notifications}/>
        </div>
      </div>
      <div>
        <RecentDriversCard data={dashboard?.recentActive} />
      </div>
    </div>
  );
};

export default Dashboard;
