const AuthLayout = ({ children }) => {
    return (
        <div className='flex flex-row w-screen h-screen '>
            <div className='h-full w-[65%] bg-primary-back'>
                <img className='w-full h-full object-cover' src={require('../../assets/images/background.png')} />
            </div>
            <div className='h-full bg-primary-back w-[35%] flex justify-center items-center overflow-auto'>
                {children}
            </div>
        </div>
    )
}

export default AuthLayout;
