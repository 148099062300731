import React from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { HiOutlineDotsVertical } from 'react-icons/hi';

const ActionMenu = ({ menuItems }) => {
  const menu = (
    <Menu>
      {menuItems?.map((item, index) => (
        <Menu.Item key={index} onClick={item.onClick}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <Button className='action-menu' type="primary" icon={<HiOutlineDotsVertical />}  />
    </Dropdown>
  );
};

export default ActionMenu;
