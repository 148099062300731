import { createBrowserRouter } from 'react-router-dom';
// import BlankLayout from '../components/Layouts/BlankLayout';
// import DefaultLayout from '../components/Layouts/DefaultLayout';
import { routes } from './routes';
// import { isAuthenticated } from '../utils/auth';
import { Navigate } from 'react-router-dom'
import Layout from '../components/Layout'

const isAuthenticated = () => {
  let user = JSON.parse(localStorage.getItem("userInfo"))
  let token = localStorage.getItem("token")
  return !!token
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />;
};

const PublicRoute = ({ element }) => {
  return isAuthenticated() ? <Navigate to="/" /> : element;
};

const finalRoutes = routes.map((route) => {
  const isAuthRoute = route.path.startsWith('/login')  // || route.path.startsWith('/password-forget') || route.path.startsWith('/password-reset');
  const element = route.layout === 'blank' ? route.element : <Layout>{route.element}</Layout>;
  return {
    ...route,
    element: isAuthRoute ? <PublicRoute element={element} /> : <PrivateRoute element={element} />,
  };
});

const router = createBrowserRouter(finalRoutes);

export default router;
