import { Button, Divider, Table, Modal, Carousel, Spin, Pagination } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/CommonHttp";
import { BiPlus } from "react-icons/bi";
import AddBanners from "./components/AddBanners";
import CustomModal from "../../components/Modal/CustomModal";
import EditBanners from "./components/EditBanners";
import ActionMenu from "../../components/ActionMenu";
import TextArea from "antd/es/input/TextArea";
import { resetCrudState } from "../../redux/reducers/crudSlice";
import { FaRegCircleQuestion } from "react-icons/fa6";

const BannersTable = () => {
  const dispatch = useDispatch();
  const bannersData = useSelector((state) => state.crud.banners.data);
  const bannersPagination = useSelector((state) => state.crud.banners.pagination || { current: 1, pageSize: 10, total: 0 });
  const addRecordSpin = useSelector((state) => state.crud.addRecordSpin);
  const editRecordSpin = useSelector((state) => state.crud.editRecordSpin);
  const deleteRecordSpin = useSelector((state) => state.crud.deleteRecordSpin);
  const addRecordSuccess = useSelector((state) => state.crud.addRecordSuccess);
  const editRecordSuccess = useSelector((state) => state.crud.editRecordSuccess);
  const deleteRecordSuccess = useSelector((state) => state.crud.deleteRecordSuccess);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [activeImage, setActiveImage] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    if (addRecordSuccess) {
      setAddModal(false);
      dispatch(resetCrudState());
    }
  }, [addRecordSuccess, dispatch]);

  useEffect(() => {
    if (editRecordSuccess) {
      setEditModal(false);
      dispatch(resetCrudState());
    }
  }, [editRecordSuccess, dispatch]);

  useEffect(() => {
    if (deleteRecordSuccess) {
      setDeleteModal(false);
      dispatch(resetCrudState());
    }
  }, [deleteRecordSuccess, dispatch]);

  useEffect(() => {
    fetchData(bannersPagination.current); // Fetch initial data for the first page
  }, [dispatch, bannersPagination.current]);

  const fetchData = (page) => {
    const params = {
      page: page,
      pageSize: bannersPagination.pageSize,
    };

    dispatch(
      DataGetAction({
        apiName: "getAllBanner",
        data: params,
        requestType: "fetch",
        recordName: "banners",
      })
    );
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handleAddData = (formData) => {
    dispatch(
      DataRequestAction({
        method: "POST",
        apiName: "addBanner",
        data: formData,
        requestType: "addRecord",
        recordName: "banners",
      })
    ).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        // Fetch the first page of data again after a new record is successfully added
        fetchData(1);
      }
    });
  };
  

  const handleEditData = (formData) => {
    dispatch(
      DataRequestAction({
        method: "PUT",
        apiName: "updateBanner",
        data: formData,
        requestType: "updateRecord",
        recordName: "banners",
      })
    ).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        // Fetch the current page of data again after a record is successfully updated
        fetchData(bannersPagination.current);
      }
    });
  };
  
  const handleDelete = () => {
    if (selectedRow) {
      dispatch(
        DataRequestAction({
          method: "DELETE",
          apiName: `deleteBanner`,
          data: { id: selectedRow?._id },
          requestType: "deleteRecord",
          recordName: "banners",
        })
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          // Fetch the first page of data again after a record is successfully deleted
          fetchData(1);
        }
      });
    }
  };
  

  const handleConfirmDelete = (record) => {
    setSelectedRow(record);
    setDeleteModal(true);
  };

  const handleDescriptionClick = (description) => {
    setSelectedDescription(description);
    setDescriptionModal(true);
  };

  const handleEdit = (record) => {
    setSelectedRow(record);
    setEditModal(true);
  };

  const handleViewImages = (images) => {
    setSelectedImages(images);
    setImageModal(true);
  };

  const handleThumbnailClick = (index) => {
    setActiveImage(index);
    carouselRef.current.goTo(index, true);
  };

  const columns2 = [
    {
      title: "ID",
      dataIndex: "bannerId",
      key: "bannerId",
      className: "table-cell font-semibold",
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      className: "table-cell font-semibold",
      render: (text, record) => (
        <div className="flex flex-row items-center gap-2">
          <img
            src={record?.image}
            alt="Avatar"
            style={{ width: "149px", height: "63px", objectFit: "cover" }}
          />
          <p className="capitalize">{record?.title}</p>
        </div>
      ),
      width: "550px"
    },
    {
      title: "Hyper Link",
      dataIndex: "hyperLink",
      key: "hyperLink",
      className: "table-cell",
      render: (text) => {
        const url = text.startsWith("http://") || text.startsWith("https://") ? text : `https://${text}`;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        );
      },
      width: "300px"
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      className: "table-cell",
      render: (text) => <a onClick={() => handleDescriptionClick(text)}>{text?.slice(0, 40) + "..."}</a>,
    },
    {
      title: "",
      key: "action",
      className: "table-cell",
      render: (text, record) => (
        <ActionMenu
          menuItems={[
            { label: "Edit", onClick: () => handleEdit(record) },
            { label: "Delete", onClick: () => handleConfirmDelete(record) },
          ]}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="font-roboto-bold text-24 text-primary-text">Banners</h2>
        <Button size="large" onClick={() => setAddModal(true)} className="font-roboto-medium text-xl home-add-button">
          <BiPlus size={20} />
          Add Banner
        </Button>
      </div>
      <Divider className="my-5" />
      <Spin spinning={deleteRecordSpin}>
        <Table
          dataSource={bannersData}
          columns={columns2}
          pagination={false} // Disable internal pagination
        />
      </Spin>
      <Pagination
        className="pt-4 flex justify-end"
        current={bannersPagination.current}
        pageSize={bannersPagination.pageSize}
        total={bannersPagination.total}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
      <CustomModal title="Add Banner" modal={addModal} setModal={setAddModal}>
        <AddBanners
          handleSubmit={handleAddData}
          setModal={setAddModal}
          loading={addRecordSpin}
        />
      </CustomModal>
      <CustomModal
        title="Edit Banner"
        modal={editModal}
        setModal={setEditModal}
      >
        <EditBanners
          record={selectedRow}
          handleSubmit={handleEditData}
          setModal={setEditModal}
          loading={editRecordSpin}
        />
      </CustomModal>
      <Modal
        title="Description"
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
        footer={null}
      >
        <Divider className="mt-0 mb-3" />
        <TextArea
          rows={4}
          value={selectedDescription}
          readOnly
          style={{ width: "100%" }}
        />
      </Modal>
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaRegCircleQuestion
              size={28}
              style={{ color: "#ff4d4f", marginRight: 10, marginTop: "10px" }}
            />
            <span className="text-lg font-roboto-bold">
              Are you sure you want to delete this information?
            </span>
          </div>
        }
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setDeleteModal(false)}
            className="cancel-button text-lg p-5"
            style={{ marginRight: "0px", fontFamily: "Roboto-Bold" }}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={handleDelete}
            className="save-button text-lg p-5"
            style={{ fontFamily: "Roboto-Bold" }}
            loading={deleteRecordSpin} // Spinner inside the delete button
          >
            OK
          </Button>,
        ]}
      >
        <p className="ml-10 mb-5">
          This information will be deleted permanently.
        </p>
      </Modal>
      <Modal
        title="Image View"
        visible={imageModal}
        footer={null}
        onCancel={() => setImageModal(false)}
        width={800}
      >
        <Divider className="mt-1 mb-2" />
        <Carousel
          ref={carouselRef}
          afterChange={(current) => setActiveImage(current)}
          initialSlide={activeImage}
        >
          {selectedImages.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Event Image ${index}`}
                style={{
                  width: "100%",
                  height: "415px",
                  border: "1px solid #000",
                }}
              />
            </div>
          ))}
        </Carousel>
        <div
          className="thumbnail-container"
          style={{ display: "flex", overflowX: "auto", marginTop: 10 }}
        >
          {selectedImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              style={{
                width: 120,
                height: 120,
                margin: "0 5px",
                cursor: "pointer",
                border: activeImage === index ? "2px solid #1890ff" : "none",
              }}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default BannersTable;
