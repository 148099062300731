import React, { useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import { Form, Input, Button, Spin } from "antd";
import { useDispatch } from "react-redux";
import { login } from "../../redux/reducers/authSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [loading, setLoading] = useState(false); // Local loading state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoading(true);
    const result = await dispatch(login(values));

    if (login.fulfilled.match(result)) {
      navigate("/"); // Navigate directly after a successful login
    } else {
      setLoading(false); // Reset loading state if login failed
    }
  };

  return (
    <AuthLayout>
      <div className="h-full w-full mx-auto p-8 flex flex-col justify-center">
        <h2 className="font-roboto-bold text-black text-[50px] text-center">Sign In</h2>
        <h6 className="font-roboto-regular text-black text-2xl text-center mb-5">
          Enter your email and password to log in!
        </h6>

        <Form
          name="login"
          autoComplete="off"
          layout="vertical"
          className="space-y-4"
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input
              autoComplete="off"
              className="custom-input text-black font-roboto-regular text-lg"
              placeholder="Enter your email"
              size="large"
              type="email"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password
              autoComplete="new-password"
              className="custom-input text-black font-roboto-regular text-lg"
              placeholder="Enter your password"
              size="large"
            />
          </Form.Item>

        

          <Form.Item className="pt-8 px-2">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full h-12 bg-black font-roboto-medium text-lg text-yellow-500 login-button"
              disabled={loading}
              style={{ height: "48px", width: "100%" }} // Fixed button size
            >
              {loading ? <Spin className="custom-spinner"  style={{ marginRight: "8px"}} /> : "Log In"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Login;
