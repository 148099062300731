import React from "react";
import { Card, Divider, Table, Tag, Avatar } from "antd";

const DashboardDriversTable = ({ data = [] }) => {
  // Dummy data for recent drivers
  const recentDrivers = [
    {
      id: 1,
      fullName: 'John Doe',
      email: 'john@example.com',
      phoneNumber: '123-456-7890',
      dateOfBirth: '1990-01-01',
      driverType: 'full-time',
      lastActivity: new Date(),
      status: 'active',
    },
    {
      id: 2,
      fullName: 'Jane Smith',
      email: 'jane@example.com',
      phoneNumber: '987-654-3210',
      dateOfBirth: '1992-02-02',
      driverType: 'part-time',
      lastActivity: new Date(),
      status: 'active',
    },
    {
      id: 3,
      fullName: 'Alex Johnson',
      email: 'alex@example.com',
      phoneNumber: '555-666-7777',
      dateOfBirth: '1985-03-03',
      driverType: 'full-time',
      lastActivity: new Date(),
      status: 'block',
    },
    {
      id: 4,
      fullName: 'Chris Lee',
      email: 'chris@example.com',
      phoneNumber: '222-333-4444',
      dateOfBirth: '1994-04-04',
      driverType: 'full-time',
      lastActivity: new Date(),
      status: 'active',
    },
    {
      id: 5,
      fullName: 'Sam Brown',
      email: 'sam@example.com',
      phoneNumber: '111-222-3333',
      dateOfBirth: '1988-05-05',
      driverType: 'part-time',
      lastActivity: new Date(),
      status: 'block',
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: "table-cell font-semibold",
      render: (text, record, index) => (
        <div>
          <span className="font-roboto-normal text-base">{index + 1}</span>
        </div>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      className: "table-cell font-semibold",

      render: (text, record) => (
        <div className="flex items-center">
          <Avatar className="border bg-gray-50" size={50} src={record?.profileImage} />
          <span className="ml-3 font-roboto-medium text-lg capitalize">{text}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "table-cell",
      width: "250px",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      className: "table-cell",
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      className: "table-cell",
      render: (text, record) => (
        <div>
          {new Date(record?.dateOfBirth).toDateString()}
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "driverType",
      key: "driverType",
      className: "table-cell",
      render: (text, record) => (
        <div className="capitalize">
          {record?.driverType}
        </div>
      ),
    },
    {
      title: "Last Activity",
      dataIndex: "lastActivity",
      key: "lastActivity",
      className: "table-cell",
      render: (text, record) => (
        <div>
          {new Date(record?.lastActivity).toLocaleTimeString()}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10px",
      className: "table-cell",
      render: (status) => (
        <Tag className="text-sm px-6 py-1 rounded capitalize" color={status === "active" ? "green" : "red"}>
          {status}
        </Tag>
      ),
    },
  ];

  return (
    <Card
      title={<span className="text-2xl font-roboto-bold">Drivers</span>}
      bordered={false}
      extra={<a className="text-lg font-roboto-medium" href="/drivers">View All</a>}
      className="w-[100%] mx-auto mt-5 drop-shadow-xl"
      bodyStyle={{ padding: '16px' }}
    >

      <Divider className="my-5" />
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content' }} // Enable horizontal scroll for smaller screens
      />
    </Card>
  );
};

export default DashboardDriversTable;
