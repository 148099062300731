import { createSlice } from "@reduxjs/toolkit";
import { DataGetAction, DataRequestAction } from "../actions/CommonHttp";
import { addRecord, deleteRecord, updateRecord } from "./CommonFn";

const initialState = {
  fetchingSpinner: false,

  addRecordSuccess: false,
  addRecordSpin: false,

  editRecordSpin: false,
  editRecordSuccess: false,

  deleteRecordSuccess: false,
  deleteRecordSpin: false,

  events: {
    data: [],
    pagination: {
      current: 1, // Initialize with page 1
      pageSize: 10, // Default page size
      total: 0, // Total records count
    },
  },

  banners: {
    data: [],
    pagination: {
      current: 1, // Initialize with page 1
      pageSize: 10, // Default page size
      total: 0, // Total records count
    },
  },
  broadcasts: {
    data: [],
    pagination: {
      current: 1, // Initialize with page 1
      pageSize: 10, // Default page size
      total: 0, // Total records count
    },
  },
  drivers: {
    data: [],
    pagination: {
      current: 1, // Initialize with page 1
      pageSize: 10, // Default page size
      total: 0, // Total records count
    },
  },

  traffics: {
    data: [],
    pagination: {
      current: 1, // Initialize with page 1
      pageSize: 10, // Default page size
      total: 0, // Total records count
    },
  },
};

const crudSlice = createSlice({
  name: "crud",
  initialState,
  reducers: {
    resetCrudState: (state) => {
      state.addRecordSuccess = false;
      state.editRecordSuccess = false;
      state.deleteRecordSuccess = false;
      state.addRecordSpin = false;
      state.editRecordSpin = false;
      state.deleteRecordSpin = false;
    },
    hideModal: (state, action) => {
      state[action.payload.modalName] = false;
      state.initialValue = null;
    },
    showModal: (state, action) => {
      state[action.payload.modalName] = true;
      state.initialValue = action.payload.payload;
    },
    resetCrudState: (state) => {
      state.addRecordSuccess = false;
      state.editRecordSuccess = false;
      state.deleteRecordSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(DataRequestAction.pending, (state, action) => {
        const { requestType, recordName } = action.meta.arg;
        let type = requestType;
        if (type == "updateRecord") {
          state.editRecordSpin = true;
        } else if (type == "deleteRecord") {
          state.deleteRecordSpin = true;
        } else {
          state.addRecordSpin = true;
        }
      })
      .addCase(DataRequestAction.fulfilled, (state, action) => {
        let type = action.payload.requestType;
        if (type == "updateRecord") {
          let Record = updateRecord(
            state[action.payload.recordName],
            action.payload.responseData.Record
          );
          state[action.payload.recordName] = [...Record];
          state.editRecordSpin = false;
          state.editRecordSuccess = true;
        } else if (type == "deleteRecord") {
          let Record = deleteRecord(
            state[action.payload.recordName],
            action.payload.responseData.Record
          );
          state[action.payload.recordName] = [...Record];
          state.deleteRecordSpin = false;
          state.deleteRecordSuccess = true;
        } else {
          let Record = addRecord(
            state[action.payload.recordName],
            action.payload.responseData.Record
          );
          state[action.payload.recordName] = [...Record];
          state.addRecordSpin = false;
          state.addRecordSuccess = true;
        }
      })
      .addCase(DataRequestAction.rejected, (state, action) => {
        const { requestType, recordName } = action.meta.arg;
        let type = requestType;
        if (type == "updateRecord") {
          state.editRecordSpin = false;
        } else if (type == "deleteRecord") {
          state.deleteRecordSpin = false;
        } else {
          state.addRecordSpin = false;
        }
      })
      .addCase(DataGetAction.pending, (state) => {
        state.fetchingSpinner = true;
      })
      .addCase(DataGetAction.fulfilled, (state, action) => {
        const { recordName, responseData } = action.payload;
        // Update records and pagination immutably
        state[recordName] = {
          ...state[recordName], // Spread existing state to preserve properties
          data: responseData.Record, // Update data immutably
          pagination: responseData.pagination, // Update pagination immutably
        };
        state.fetchingSpinner = false;
      })
      .addCase(DataGetAction.rejected, (state, action) => {
        const { recordName } = action.meta.arg;
        state.fetchingSpinner = false;
        state[recordName] = {
          ...state[recordName], // Spread existing state to preserve properties
          data: [], // Reset data on error
          pagination: { current: 1, pageSize: 10, total: 0 }, // Reset pagination
        };
      });
  },
});

export const { hideModal, showModal, resetCrudState } = crudSlice.actions;

export default crudSlice.reducer;
