import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./component/Header";
import { Button, Layout, Menu, Modal, Drawer } from "antd";
import Dashboard from "../../assets/images/vendors/Dashboard.jsx";
import Events from "../../assets/images/vendors/Events.jsx";
import Traffic from "../../assets/images/vendors/Traffic.jsx";
import Setting from "../../assets/images/vendors/Setting.jsx";
import Broadcast from "../../assets/images/vendors/Broadcast.jsx";
import Banner from "../../assets/images/vendors/Banner.jsx";
import User from "../../assets/images/vendors/User.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { logout } from "../../redux/reducers/authSlice.js"; // Import the logout action

const { Content, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Dashboard", "/", <Dashboard />),
  getItem("Drivers", "/drivers", <User />),
  getItem("Broadcast", "/broadcast", <Broadcast />),
  getItem("Events", "/events", <Events />),
  getItem("Traffic", "/traffic", <Traffic />),
  getItem("Banner", "/banners", <Banner />),
  getItem("Settings", "/settings", <Setting />),
];

const Index = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const dispatch = useDispatch();
  const isSessionExpired = useSelector((state) => state.auth.isSessionExpired);

  const handleMenuClick = ({ key }) => {
    navigate(key);
    setDrawerVisible(false); // Close drawer when an item is clicked
  };

  const handleLogout = () => {
    dispatch(logout()); // Dispatch logout action
    navigate("/login"); // Redirect to login page
  };

  const handleLogin = () => {
    dispatch(logout()); // Clear session and redirect
    navigate("/login");
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };


  return (
    <Layout className="h-screen w-screen">
      <Sider
        className="shadow-custom-light bg-primary-back flex flex-col justify-between"
        style={{
          display: responsive ? "none" : "block",
          backgroundColor: "#E8BC0E",
        }}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          setResponsive(broken);
          setCollapsed(broken);
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="min-h-screen flex flex-col justify-between">
          <div>
            <div className="p-2 w-full bg-white">
              <img className="bg-white mt-1.5" src={require("../../assets/images/logo.png")} />
            </div>
            <div className="">
              <Menu
                className="mt-8 bg-primary-back text-18 font-roboto-medium m1-2"
                defaultSelectedKeys={["/"]}
                selectedKeys={[currentPath]}
                onClick={handleMenuClick}
                mode="inline"
                items={items}
              />
            </div>
          </div>
          <div className="p-5">
            <div
              type="primary"
              className="w-full flex flex-row items-center gap-2 ml-4 font-roboto-medium cursor-pointer text-white text-xl"
              onClick={handleLogout}
            >
              <LogoutIcon className="w-5 h-5" /> Log Out
            </div>
          </div>
        </div>
      </Sider>
      <Drawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={toggleDrawer}
        visible={drawerVisible}
        bodyStyle={{ padding: 0 }}
      >
        <div className="bg-primary-back h-full flex flex-col justify-between">
          <Menu
            className="bg-primary-back text-18 font-roboto-medium"
            selectedKeys={[currentPath]}
            onClick={handleMenuClick}
            mode="inline"
            items={items}
          />
          <div className="p-5">
            <div
              type="primary"
              className="w-full flex flex-row items-center gap-2 ml-4 font-roboto-medium cursor-pointer text-white text-xl"
              onClick={handleLogout}
            >
              <LogoutIcon className="w-5 h-5" /> Log Out
            </div>
          </div>
        </div>
      </Drawer>
      <Layout>
        <Header responsive={responsive} toggleDrawer={toggleDrawer} />
        <Content className="overflow-y-auto overflow-x-auto h-full p-5">
          <div className="bg-white min-h-[400px] p-5">{children}</div>
        </Content>
      </Layout>
      {/* Session Expired Modal */}
      <Modal
        visible={isSessionExpired}
        centered // Center the modal on the screen
        closable={false}
        footer={null} // We'll create a custom footer
        bodyStyle={{ textAlign: "center" }} // Center the content inside the modal
      >
        <p style={{ fontSize: "30px", fontFamily: "Roboto-Medium" }}>Session Expired</p>
        <p className="mt-2 font-roboto-regular text-[16px]">Your current session has expired. Please log in again to continue.</p>
        <Button
          key="login"
          onClick={handleLogin}
          style={{
            width: "200px",
            fontSize: "18px",
            padding: "20px",
            fontFamily: "Roboto-Medium",
            backgroundColor: "#E8BC0E",
            color: "black",
            borderColor: "#E8BC0E",
            marginTop: "20px",
          }}
        >
          Take me to login
        </Button>
      </Modal>
    </Layout>
  );
};

export default Index;
