import { Image } from 'antd';
import React, { useEffect } from 'react';
import { getAuthUser } from '../../../redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IoMenuOutline } from 'react-icons/io5';

const Header = ({ responsive, toggleDrawer }) => {
  const dispatch = useDispatch()
  const authUser = useSelector(state => state.auth.authUser)
  useEffect(() => {
    dispatch(getAuthUser());
  }, [dispatch]);
  return (
    <div className="w-full max-w-screen mx-auto p-6 shadow-custom-light bg-white">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          {responsive && <IoMenuOutline size={20} onClick={toggleDrawer} />}
          <h2 className="font-roboto-bold text-primary-text text-[27px]">
            Welcome to Admin portal
          </h2>
        </div>
        <div className="flex flex-row items-center gap-3">
          <div className="w-10 h-10 bg-[#D9D9D9] rounded-full">
            <img
              className="w-full h-full rounded-full object-cover"
              src={authUser && authUser?.profileImage ? authUser?.profileImage : "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png"}
              alt="Profile"
            />
          </div>
          <h2 className="font-roboto-bold text-primary-text text-18 capitalize">
            {authUser?.fullName}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Header;
