import * as React from "react";
const Broadcast = () => (
    <svg
        className=" mr-3"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.4727 14.5161L8.76391 15.6643L6.42048 17.2394C5.51613 17.837 4.41216 18.0535 3.34887 17.8417C2.28558 17.6299 1.34904 17.007 0.743099 16.1084C0.137154 15.2099 -0.0891455 14.1086 0.113456 13.0441C0.316057 11.9797 0.931158 11.0383 1.82488 10.4249L5.97254 7.65182L10.4727 14.5161ZM19.9999 11.2C17.015 11.3576 14.1155 12.2504 11.5594 13.799L11.4972 13.8404L6.99701 6.96374C9.39062 5.13514 11.2978 2.74659 12.5507 0.00830078L15.2757 4.10364L17.0468 6.76478L19.9999 11.2ZM17.7809 4.86633C17.7813 5.03731 17.7391 5.2057 17.658 5.35625C17.5769 5.50679 17.4594 5.63474 17.3164 5.72851L16.1675 4.00001C16.3392 3.88883 16.5394 3.8298 16.744 3.83006C17.019 3.83006 17.2827 3.93924 17.4772 4.13358C17.6717 4.32792 17.7809 4.5915 17.7809 4.86633ZM13.8448 18.8601C13.8427 19.1118 13.7576 19.3559 13.6027 19.5543C13.4477 19.7528 13.2316 19.8947 12.9878 19.958C12.744 20.0213 12.4861 20.0024 12.2541 19.9043C12.0221 19.8063 11.829 19.6345 11.7046 19.4156L9.40265 16.2156L11.3479 14.9057C11.5138 14.7938 11.6839 14.6819 11.8539 14.5782L13.6416 18.2135C13.7741 18.4031 13.845 18.6288 13.8448 18.8601Z"
            fill="currentColor"
        />
    </svg>
);
export default Broadcast;
