import React from 'react';
import { Card } from 'antd';
import moment from 'moment';
const notifications = [
  { text: 'New driver has been added', date: '12-Jul-2024' },
  { text: 'A driver has been blocked', date: '10-Jul-2024' },
  { text: 'New broadcast has been added', date: '11-Jul-2024' },
  { text: 'The password was changed.', date: '10-Jul-2024' },
  { text: 'New driver has been added', date: '12-Sep-2023' },
  { text: 'New driver has been added', date: '12-Jul-2024' },
  { text: 'A driver has been blocked', date: '10-Jul-2024' },
  { text: 'New broadcast has been added', date: '11-Jul-2024' },
  { text: 'The password was changed.', date: '10-Jul-2024' },
  { text: 'New driver has been added', date: '12-Sep-2023' },
  // Add more notifications as needed
];

const NotificationCard = ({ notifications }) => {
  return (
    <Card
      title={<span className="text-2xl font-roboto-bold">Notification</span>}
      bordered={false}
      className="w-full h-[300px] mx-auto drop-shadow-xl"
      bodyStyle={{ padding: '16px', maxHeight: '240px', overflowY: 'auto' }}
    >
      <ul className="list-none p-0 m-0">
        {notifications?.map((notification, index) => (
          <li key={index} className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <span className="w-2 h-2 bg-black rounded-full mr-3"></span>
              <span className="text-gray-800 text-lg font-roboto-regular">{notification.message}</span>
            </div>
            <p>{moment(notification.createdAt).format("DD-MM-YYYY")}</p> {/* Format date */}
          </li>
        ))}
      </ul>
    </Card>
  );
}

export default NotificationCard;
