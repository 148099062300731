import React from 'react';

const StatCard = ({ backgroundColor, graphImage, title, count }) => {
  return (
    <div
      className="relative p-4 rounded-lg shadow-lg text-white max-w-[290px] h-[120px]"
      style={{ backgroundColor: backgroundColor }}
    >
      {/* Graph Image Overlay */}
      <img
        src={graphImage}
        alt="Graph"
        className="absolute top-10 left-0 w-[290px] h-[80px] object-cover opacity-30 rounded-lg"
        style={{ zIndex: 0 }}
      />
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-xl font-roboto-bold">{title}</h2>
        <p className="text-3xl font-roboto-bold mt-2">{count}</p>
      </div>
    </div>
  );
};

export default StatCard;
