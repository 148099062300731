// Remove lazy import
// import { lazy } from 'react';

// Directly import the components
import Login from "../screens/Auth/Login";
import Dashboard from "../screens/Dashboard";
import EventsTable from "../screens/Events/EventsTable";
import Traffic from "../screens/Traffic";

import Users from "../screens/Users/UsersTable";
import NotFound from "../screens/NotFound";
import TrafficTable from "../screens/Broadcast";
import Banner from "../screens/Banner";
import Settings from "../screens/Settings";

const routes = [
  {
    path: "/login",
    element: <Login />,
    layout: "blank",
  },
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/events",
    element: <EventsTable />,
  },
  {
    path: "/traffic",
    element: <Traffic />,
  },
  {
    path: "/broadcast",
    element: <TrafficTable />,
  },
  {
    path: "/drivers",
    element: <Users />,
  },
  {
    path: "/banners",
    element: <Banner />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export { routes };
