// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { LoginSuccess } from "./CommonFn";
// import { message } from "antd";
// const ROOT_URL = process.env.REACT_APP_ROOT_URL;

// let auth = localStorage.getItem("userInfo")
// let user = auth ? JSON.parse(auth) : null

// const initialState = {
//     loader: false,
//     role: "",
//     token: localStorage.getItem("token"),
//     authUser: user,
//     isLogin: !!user,
//     loginSuccess: false
// };


// const postFunc = async (method, apiName, data) => {
//     try {
//         const response = await fetch(`${ROOT_URL}/${apiName}`, {
//             method: method,
//             headers: new Headers({
//                 "Content-Type": "application/json",
//             }),
//             body: JSON.stringify(data),
//         });
//         const responseData = await response.json();
//         if (responseData.status === "SUCCESS") {
//             message.success(responseData.message)
//         }
//         if (responseData.status === "ERROR" || response.status === 404) {
//             message.error(responseData.message)
//         }
//         return responseData
//     } catch (error) {
//         console.log(error)
//         message.error(error.message)
//     }
// }

// const getFunc = async (apiName, data) => {
//     const token = localStorage.getItem("token");
//     try {
//         let param = "";
//         if (data) {
//             param = `/${JSON.stringify(data)}`;
//         }
//         const headers = {
//             "Content-Type": "application/json",
//             Authorization: token,
//             APIType: "web",
//         };
//         const response = await fetch(`${ROOT_URL}/${apiName}${param}`, { headers });
//         const responseData = await response.json();
//         if (responseData.status === "ERROR" || response.status === 404) {
//             message.error(responseData.message)
//         }
//         return responseData
//     } catch (error) {
//         console.log(error)
//         message.error(error.message)
//     }
// }

// const createApiThunk = (typePrefix, method, apiName) => {
//     return createAsyncThunk(typePrefix, async (payload) => {
//         console.log(payload)
//         const data = await postFunc(method, apiName, payload);
//         if (data.status === 'SUCCESS' && data?.user) {
//             let Record = LoginSuccess(data.user);
//             return { user: Record };
//         }
//         console.log(data.message)
//         throw new Error(data.message);
//     });
// };

// const getApiThunk = (typePrefix, apiName) => {
//     return createAsyncThunk(typePrefix, async (payload) => {
//         console.log(payload)
//         const data = await getFunc(apiName, payload);
//         if (data.status === 'SUCCESS' && data?.Record) {
//             return { user: data?.Record };
//         }
//         console.log(data.message)
//         throw new Error(data.message);
//     });
// };

// export const login = createApiThunk('auth/login', 'POST', 'adminLogin');
// export const signIn = createApiThunk('auth/signIn', 'POST', 'signUp');
// export const getAuthUser = getApiThunk('get/auth', 'getProfile');
// export const updateUserProfile = createAsyncThunk('update/user', async ({ apiName, data }) => {
//     const token = localStorage.getItem("token");
//     const response = await fetch(`${ROOT_URL}/${apiName}`, {
//         method: 'PUT',
//         headers: new Headers({
//             "Content-Type": "application/json",
//             Authorization: token,
//         }),
//         body: JSON.stringify(data),
//     });
//     const responseData = await response.json();
//     console.log("===responseData==", responseData)
//     if (responseData.status === "SUCCESS" && responseData?.Record) {
//         message.success(responseData.message);
//         return { user: responseData?.Record };
//     }

//     if (responseData.status === "ERROR" || response.status === 404) {
//         const errorMessage = responseData.message;
//         message.error(errorMessage);

//     }

//     throw new Error(responseData.message);
// });


// // Updated authSlice with loader reset
// const authSlice = createSlice({
//     name: "auth",
//     initialState,
//     reducers: {
//         logout: (state) => {
//             localStorage.clear();
//             state.authUser = null;
//             state.isLogin = false;
//             state.token = "";
//             state.role = "";
//             state.loader = false; // Reset loader on logout
//         },
//     },
//     extraReducers: (builder) => {
//         //login
//         builder.addCase(login.pending, (state) => {
//             state.loader = true;
//         });
//         builder.addCase(login.fulfilled, (state, action) => {
//             state.authUser = action.payload.user;
//             state.token = action.payload.user.token;
//             state.role = "admin";
//             state.isLogin = true;
//             state.loginSuccess = true;
//             state.loader = false; // Reset loader on success
//         });
//         builder.addCase(login.rejected, (state) => {
//             state.loader = false;
//         });

//         //signIn
//         builder.addCase(signIn.pending, (state) => {
//             state.loader = true;
//         });
//         builder.addCase(signIn.fulfilled, (state) => {
//             state.loader = false;
//         });
//         builder.addCase(signIn.rejected, (state) => {
//             state.loader = false;
//         });

//         //get User
//         builder.addCase(getAuthUser.pending, (state) => {
//             state.loader = true;
//         });
//         builder.addCase(getAuthUser.fulfilled, (state, action) => {
//             state.authUser = action?.payload?.user;
//             state.loader = false;
//         });
//         builder.addCase(getAuthUser.rejected, (state) => {
//             state.loader = false;
//         });

//         //update user
//         builder.addCase(updateUserProfile.pending, (state) => {
//             state.loader = true;
//         });
//         builder.addCase(updateUserProfile.fulfilled, (state, action) => {
//             state.authUser = action?.payload?.user;
//             state.loader = false;
//         });
//         builder.addCase(updateUserProfile.rejected, (state) => {
//             state.loader = false;
//         });
//     }
// });





// export const { logout } = authSlice.actions;

// export default authSlice.reducer;




import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { LoginSuccess } from "./CommonFn"; // Assuming you have this utility
const ROOT_URL = process.env.REACT_APP_ROOT_URL;

// Retrieve initial user and token data from localStorage
let auth = localStorage.getItem("userInfo");
let user = auth ? JSON.parse(auth) : null;

const initialState = {
    loader: false,
    role: "",
    token: localStorage.getItem("token"),
    authUser: user,
    isLogin: !!user,
    loginSuccess: false,
    isSessionExpired: false, // State to track session expiration
};

// Helper function to handle session expiration
const handleSessionExpiration = (dispatch) => {
    dispatch(sessionExpired()); // Dispatch session expired action
    localStorage.clear(); // Clear local storage
    // Do not redirect here; let the app's state handle it via the modal
};

// POST function with session expiration handling
const postFunc = async (method, apiName, data, dispatch) => {
    try {
        const response = await fetch(`${ROOT_URL}/${apiName}`, {
            method: method,
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"), // Add token to headers
            }),
            body: JSON.stringify(data),
        });

        if (response.status === 401) {
            handleSessionExpiration(dispatch); // Handle session expiration
            return;
        }

        const responseData = await response.json();
        if (responseData.status === "SUCCESS") {
            message.success(responseData.message);
        } else if (responseData.status === "ERROR" || response.status === 404) {
            message.error(responseData.message);
        }
        return responseData;
    } catch (error) {
        console.log(error);
        message.error(error.message);
    }
};

// GET function with session expiration handling
const getFunc = async (apiName, data, dispatch) => {
    const token = localStorage.getItem("token");
    try {
        let param = "";
        if (data) {
            param = `/${JSON.stringify(data)}`;
        }
        const headers = {
            "Content-Type": "application/json",
            Authorization: token,
            APIType: "web",
        };
        const response = await fetch(`${ROOT_URL}/${apiName}${param}`, { headers });

        if (response.status === 401) {
            handleSessionExpiration(dispatch); // Handle session expiration
            return;
        }

        const responseData = await response.json();
        if (responseData.status === "ERROR" || response.status === 404) {
            message.error(responseData.message);
        }
        return responseData;
    } catch (error) {
        console.log(error);
        message.error(error.message);
    }
};

// Function to create API thunks
const createApiThunk = (typePrefix, method, apiName) => {
    return createAsyncThunk(typePrefix, async (payload, { dispatch }) => {
        const data = await postFunc(method, apiName, payload, dispatch);
        if (data.status === 'SUCCESS' && data?.user) {
            let Record = LoginSuccess(data.user);
            return { user: Record };
        }
        throw new Error(data.message);
    });
};

// Function to create GET API thunks
const getApiThunk = (typePrefix, apiName) => {
    return createAsyncThunk(typePrefix, async (payload, { dispatch }) => {
        const data = await getFunc(apiName, payload, dispatch);
        if (data.status === 'SUCCESS' && data?.Record) {
            return { user: data?.Record };
        }
        throw new Error(data.message);
    });
};

// Define thunks for authentication-related actions
export const login = createApiThunk('auth/login', 'POST', 'adminLogin');
export const signIn = createApiThunk('auth/signIn', 'POST', 'signUp');
export const getAuthUser = getApiThunk('get/auth', 'getProfile');
export const updateUserProfile = createAsyncThunk('update/user', async ({ apiName, data }, { dispatch }) => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${ROOT_URL}/${apiName}`, {
        method: 'PUT',
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: token,
        }),
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.status === 401) {
        handleSessionExpiration(dispatch); // Handle session expiration
        return;
    }
    if (responseData.status === "SUCCESS" && responseData?.Record) {
        message.success(responseData.message);
        return { user: responseData?.Record };
    }
    if (responseData.status === "ERROR" || response.status === 404) {
        message.error(responseData.message);
    }
    throw new Error(responseData.message);
});

// Redux slice for authentication state
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.clear();
            state.authUser = null;
            state.isLogin = false;
            state.token = "";
            state.role = "";
            state.loader = false;
            state.isSessionExpired = false; // Reset on logout
        },
        sessionExpired: (state) => {
            console.log("Session Expired Triggered"); // Add this log
            state.isSessionExpired = true;
            state.isLogin = false;
            state.authUser = null;
            state.token = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.authUser = action.payload.user;
            state.token = action.payload.user.token;
            state.role = "admin";
            state.isLogin = true;
            state.loginSuccess = true;
            state.loader = false; // Reset loader on success
            state.isSessionExpired = false; // Reset session expired on successful login
        });
        builder.addCase(login.rejected, (state) => {
            state.loader = false;
        });

        builder.addCase(signIn.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(signIn.fulfilled, (state) => {
            state.loader = false;
        });
        builder.addCase(signIn.rejected, (state) => {
            state.loader = false;
        });

        builder.addCase(getAuthUser.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(getAuthUser.fulfilled, (state, action) => {
            state.authUser = action?.payload?.user;
            state.loader = false;
        });
        builder.addCase(getAuthUser.rejected, (state) => {
            state.loader = false;
        });

        builder.addCase(updateUserProfile.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(updateUserProfile.fulfilled, (state, action) => {
            state.authUser = action?.payload?.user;
            state.loader = false;
        });
        builder.addCase(updateUserProfile.rejected, (state) => {
            state.loader = false;
        });
    }
});

// Export actions and reducer
export const { logout, sessionExpired } = authSlice.actions;
export default authSlice.reducer;
