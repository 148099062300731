
export const theme = {

    components: {
        Menu: {
            algorithm: true,
            itemBg: 'transparent',
            itemActiveBg: 'transparent',
            itemSelectedColor: '#000000',
            itemColor: '#ffffff',
            itemSelectedBg: "",

        },
        Button: {
            defaultActiveBg: "#E8BC0E",
            defaultActiveColor: "#010101",
            defaultBg: "#E8BC0E",
            defaultColor: "#010101",
            defaultHoverColor: "#010101",
            defaultHoverBg: "#E8BC0E99",
            textHoverBg: "#010101"
        }
    },
};
